import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { ProductMaster,Country,State, ServiceActions, Variants, Authors, Category, Tags} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ProductMasterService implements ServiceActions {

  public pageName = 'ProductMaster';
  public dataList = new Subject<Array<ProductMaster>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.ProductMasterApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<ProductMaster> = [];
          response.result.Products.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getItemsList(prod_id: number) {
    const url = this.config.ipAddress + this.config.ProductMasterApi[ApiActions.GetSingle];
    const data = new Subject<Array<ProductMaster>>();
    const fData = new FormData();
    fData.append('prod_id', prod_id + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.Reviews;
          data.next(result);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getVariantItemsList(prod_id: number) {
    const url = this.config.ipAddress + this.config.ProductMasterApi[ApiActions.GetUserPermissions];
    const data = new Subject<Array<ProductMaster>>();
    const fData = new FormData();
    fData.append('prod_id', prod_id + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.productvariants;
          data.next(result);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getTags() {
    const resultList = new Subject<Array<Tags>>();
    let data = [];
    const url = this.config.ipAddress + this.config.TagsApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.Tags) {
          data = response.result.Tags;
          resultList.next(data);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  // public getCountrys(): Subject<Array<Country>> {
  //   const url = this.config.ipAddress + this.config.CountryApi[ApiActions.Get];
  //   const dataArray: Array<Country> = [];
  //   const data = new Subject<Array<Country>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.Country.map(element => {
  //           element.status = Number(element.rec_status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }
  // public getStates(): Subject<Array<State>> {
  //   const url = this.config.ipAddress + this.config.StateApi[ApiActions.Get];
  //   const dataArray: Array<State> = [];
  //   const data = new Subject<Array<State>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.State.map(element => {
  //           element.status = Number(element.rec_status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }

  public addData(objData: ProductMaster) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }
 
  private fillData(objData: ProductMaster) {
    const fData = new FormData();
    fData.append('prod_code', objData.prod_code);
    fData.append('prod_name', objData.prod_name);
    fData.append('prod_id', objData.prod_id + '');
    fData.append('author_id', objData.author_id + '');
    fData.append('trending', objData.trending + '');
    fData.append('popular', objData.popular + '');
    fData.append('catg_id', objData.catg_id + '');
    fData.append('prod_desc', objData.prod_desc);
    fData.append('prod_sdesc', objData.prod_sdesc);
    fData.append('prod_image', objData.prod_image);
    fData.append('rec_status', objData.rec_status ? '1' : '0');
    const items = objData.items.map(e => {
      return {
        variant_id: e.variant_id,
        variant_code: e.variant_code,
        variant_name: e.variant_name,
        variant_attachment: e.variant_attachment,
        attachments: e.attachments,

      };
    });
  fData.append('tagitems', objData.tag_id);
  fData.append('items', JSON.stringify(items));
    return fData;
  }

  public updateData(objData: ProductMaster) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public updateAttachmentsData(objData: any) {
    const fData = this.fillAttachmentsData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.DeleteTask), this.pageName);
  }

  private fillAttachmentsData(objData: any) {
    console.log(objData);
    const fData = new FormData();
    fData.append('variant_id', objData.items.variant_id);
    fData.append('variant_attachment', objData.items.variant_attachment);
   // fData.append('attachments', objData.items.attachments);
    const items = objData.items.attachments;
    fData.append('items', JSON.stringify(items));
    return fData;
  }

  public updatedocAttachmentsData(objData: any) {
    const fData = this.filldocAttachmentsData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.GetFiless), this.pageName);
  }

  private filldocAttachmentsData(objData: any) {
   // console.log(objData);
    const fData = new FormData();
    fData.append('prod_id', objData.items.prod_id);
   // fData.append('variant_attachment', objData.items.variant_attachment);
   // fData.append('attachments', objData.items.attachments);
    const previousitems = objData.items.attachments;
    const items = objData.items.variant_attachment;
    fData.append('items', JSON.stringify(items));
    fData.append('previousitems', JSON.stringify(previousitems));
    return fData;
  }

  private fillReviewData(objData: ProductMaster) {
    const fData = new FormData();
    fData.append('prod_id', objData.prod_id + '');
    fData.append('rec_status', objData.rec_status ? '1' : '0');
    const reviewitems = objData.reviewitems.map(e => {
      return {
        review_id: e.review_id,
        review: e.review,
        rating: e.rating,
      };
    });

  fData.append('reviewitems', JSON.stringify(reviewitems));
    return fData;
  }
  
  public updateReviewData(objData: ProductMaster) {
    const fData = this.fillReviewData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('prod_code', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: ProductMaster) {
    const fData = new FormData();
    fData.append('status', objData.status ? '1' : '0');
    fData.append('prod_id', objData.prod_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

  public getVariants(): Subject<Array<Variants>> {
    const url = this.config.ipAddress + this.config.VariantsApi[ApiActions.Get];
    const dataArray: Array<Variants> = [];
    const data = new Subject<Array<Variants>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Variants.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getAuthors(): Subject<Array<Authors>> {
    const url = this.config.ipAddress + this.config.AuthorsApi[ApiActions.Get];
    const dataArray: Array<Authors> = [];
    const data = new Subject<Array<Authors>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Authors.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getCategories(): Subject<Array<Category>> {
    const url = this.config.ipAddress + this.config.CategoryApi[ApiActions.Get];
    const dataArray: Array<Category> = [];
    const data = new Subject<Array<Category>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.categories.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

}
