import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import {
  ProductUOM,
  Blogsetup,
  EquipmentMaster,
  ProcessParams,
  ProcessStep,
  PCItem,
  BlogComments,
  Tags,
  User
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BlogsetupService {

  public pageName = 'Blogs';
  public dataList = new Subject<Array<Blogsetup>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private common: CommonDataService,
    private util: UtilService,
    private router:Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.BlogsApi[apiOption];
  }

  public getProductsData() {
    const resultList = new Subject<Array<Blogsetup>>();
    const url = this.config.ipAddress + this.config.ProductMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productList) {
          const list = response.result.productList.map(e => {
            //  tslint:disable-next-line: no-string-literal
            e['stdBatchSize'] = 0;
            return e;
          });
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public getBlogComments(blog_id : number): Subject<Array<BlogComments>> {
    const url = this.config.ipAddress + this.config.BlogCommentsApi[ApiActions.Get];
    const dataArray: Array<BlogComments> = [];
    const fData = new FormData();
    fData.append('blog_id', blog_id + '');
    const data = new Subject<Array<BlogComments>>();
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.BlogComments.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getauthors(): Subject<Array<User>> {
    const url = this.config.ipAddress + this.config.AuthorsApi[ApiActions.Get];
    const dataArray: Array<User> = [];
    const data = new Subject<Array<User>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Authors.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getUsers(): Subject<Array<User>> {
    const url = this.config.ipAddress + this.config.userApi[ApiActions.Get];
    const dataArray: Array<User> = [];
    const data = new Subject<Array<User>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.userslist.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }


  public getDataRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Blogsetup> = [];
          response.result.Blogs.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }



  public getItemsList(blog_id: number) {
    const url = this.config.ipAddress + this.config.BlogsApi[ApiActions.Delete];
    const data = new Subject<Array<PCItem>>();
    const fData = new FormData();
    fData.append('blog_id', blog_id + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.BlogComments;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getTags() {
    const resultList = new Subject<Array<Tags>>();
    let data = [];
    const url = this.config.ipAddress + this.config.TagsApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.Tags) {
          data = response.result.Tags;
          resultList.next(data);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public getBookmarksList(blog_id: number) {
    const url = this.config.ipAddress + this.config.BlogsApi[ApiActions.GetSingle];
    const data = new Subject<Array<PCItem>>();
    const fData = new FormData();
    fData.append('blog_id', blog_id + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.blogbookmarks;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public updateData(objData: Blogsetup) {
    // objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public updatecommentData(itemObj: Blogsetup) {
    // objData.status = this.util.getSaveStatus();
    return this.CommentSave(this.getUrl(ApiActions.GetUserPermissions), itemObj);
  }

  private CommentSave(action: string, itemObj: Blogsetup) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    // fData.append('status', objData.status + '');
    fData.append('blog_id', itemObj.blog_id + '');
    fData.append('comment_desc', itemObj.comment_desc);
    fData.append('parent_id', itemObj.parent_id + '');
    fData.append('author_id', itemObj.author_id + '');

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

  public saveDraft(objData: Blogsetup) {
    // objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.Create), objData);
  }

  private Save(action: string, objData: Blogsetup) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    // fData.append('status', objData.status + '');
    fData.append('blog_id', objData.blog_id + '');
    fData.append('blog_title', objData.blog_title);
    fData.append('short_desc', objData.short_desc);
    fData.append('long_desc', objData.long_desc);
    fData.append('blog_likes', objData.blog_likes);
    fData.append('blog_attach', objData.blog_attach);
    console.log(objData.blog_attach);
    // for (var i = 0; i < objData.blog_attach.length; i++) { 
    //   fData.append("blog_attach", objData.blog_attach[i]);
    // }
   
    // const items = objData.items.map(e => {
    //   return {
    //     comment_desc: e.comment_desc,
    //     parent_id: e.parent_id,
    //     author_id: e.author_id
    //   };
    // });

    // const tagitems = objData.blogtagitems.map(e => {
    //   return {
    //     tag_id: e.tag_id
    //   };
    // });

    fData.append('tagitems', objData.tag_id);
    // fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

  public updateStatus(objData: Blogsetup) {
    const fData = new FormData();
    fData.append('status', objData.status ? '1' : '0');
    fData.append('blog_id', objData.blog_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

  public deleteData(itemObj: Blogsetup) {
    const fData = new FormData();
    fData.append('comment_id', itemObj.comment_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.GetTasks), this.pageName);
  }

}
