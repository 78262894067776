import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { Filesharing, ProductMaster, Variants, User } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class FilesharingService {

  public pageName = 'Filesharing';
  public dataList = new Subject<Array<Filesharing>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.FileshareApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Filesharing> = [];
          response.result.Fileshare.map(element => {
            element.status = element.status + '' === '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getProducts(): Subject<Array<ProductMaster>> {
    const url = this.config.ipAddress + this.config.ProductMasterApi[ApiActions.Get];
    const dataArray: Array<ProductMaster> = [];
    const data = new Subject<Array<ProductMaster>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Products.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getVariants(id: number): Subject<Array<Filesharing>> {
    const url = this.config.ipAddress + this.config.FileshareApi[ApiActions.Delete];
    const dataArray: Array<Filesharing> = [];
    const data = new Subject<Array<Filesharing>>();
    const fData = new FormData();
    fData.append('type_id', id + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Fileshare.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getUsers(): Subject<Array<User>> {
    const url = this.config.ipAddress + this.config.userApi[ApiActions.Get];
    const dataArray: Array<User> = [];
    const data = new Subject<Array<User>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.userslist.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public addData(objData: Filesharing) {
    const fData = new FormData();
    fData.append('fileshare_id', objData.fileshare_id + '');
    fData.append('type_id', objData.type_id + '');
    // fData.append('fileshare_image', objData.fileshare_image + '');
    // fData.append('user_id', objData.user_id + '');
    // fData.append('prod_id', objData.prod_id + '');
    fData.append('variant_id', objData.variant_id + '');
     const items = objData.fileshare_image;
    // const items = objData.fileshare_image.map(e => {
    //   return {
    //     fileshare_image: e.fileshare_image,
    //   };
    // });
     fData.append('items', JSON.stringify(items));
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  public updateData(objData: Filesharing) {
    const fData = new FormData();
    fData.append('fileshare_id', objData.fileshare_id + '');
    fData.append('type_id', objData.type_id + '');
    fData.append('fileshare_image', objData.fileshare_image + '');
   // fData.append('user_id', objData.user_id + '');
    //fData.append('prod_id', objData.prod_id + '');
    fData.append('variant_id', objData.variant_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public updateAttachmentsData(objData: any) {
    const fData = this.fillAttachmentsData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.DeleteTask), this.pageName);
  }

  private fillAttachmentsData(objData: any) {
    //console.log(objData);
    const fData = new FormData();
    fData.append('variant_id', objData.fileshare_image.fileshare_id);
    //fData.append('variant_attachment', objData.fileshare_image.variant_attachments);
   // fData.append('attachments', objData.items.attachments);
    const items = objData.fileshare_image.attachments;
    const variantitems = objData.fileshare_image.variant_attachments;
    fData.append('items', JSON.stringify(items));
    fData.append('variantitems', JSON.stringify(variantitems));
    return fData;
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('fileshare_id', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Filesharing) {
    const fData = new FormData();
    fData.append('status', objData.status ? '1' : '0');
    fData.append('fileshare_id', objData.fileshare_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }
}
