import { Component, OnInit, OnDestroy } from '@angular/core';
import { MasterActions, GridHeader, PostComments, defaultPostComments, GridHeaderEx, Country, User, Posts } from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, PostCommentsService,UserType,UtilService } from 'src/app/service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-postcomments',
  templateUrl: './postcomments.component.html',
  styleUrls: ['./postcomments.component.scss']
})
export class PostCommentsComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<PostComments>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: PostComments = defaultPostComments;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public countryList: Array<SelectItem> = [];
  public selectedCountry: Country;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public postcommentsList: Array<SelectItem> = [];
  public selectedPostComment: PostComments;

  public postList: Array<SelectItem> = [];
  public selectedPost: Posts;

  public userList: Array<SelectItem> = [];
  public selectedUser: User;

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	  { field: 'comments_id', header: 'Id', isVisible: false, isDefault: true },
    // { field: 'Post_id', header: 'Post', isVisible: true, isDefault: true },
    { field: 'post_title', header: 'Post ', isVisible: true, isDefault: true },
    { field: 'user_fname', header: 'User', isVisible: true, isDefault: true },
    { field: 'comment_desc', header: 'Comment Description', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private dataService: PostCommentsService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
      // this.dataService.getCountrys()
      // .subscribe(data => {
      //   this.countryList = data.map(ele => {
      //     return { label: ele.country_name, value: ele };
      //   });
      // });
      this.dataService.getUsers()
      .subscribe(data => {
        this.userList = data.map(ele => {
          return { label: ele.user_fname, value: ele };
        });
      });
      this.dataService.getPosts()
      .subscribe(data => {
        this.postList = data.map(ele => {
          return { label: ele.post_title, value: ele };
        });
      });
      this.dataService.getPostComments()
      .subscribe(data => {
        this.postcommentsList = data.map(ele => {
          return { label: ele.comment_desc, value: ele };
        });
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultPostComments);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
	   this.bindDropDownList();
    }
  }
  private bindDropDownList() {

    if (this.dataObj.country_id) {
      const item = this.countryList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.country_id == this.dataObj.country_id);
      this.selectedCountry = item ? item.value : undefined;
    }
    if (this.dataObj.user_id) {
      const item = this.userList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.user_id == this.dataObj.user_id);
      this.selectedUser = item ? item.value : undefined;
    }
    if (this.dataObj.post_id) {
      const item = this.postList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.post_id == this.dataObj.post_id);
      this.selectedPost = item ? item.value : undefined;
    }
    if (this.dataObj.comment_id) {
      const item = this.postcommentsList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.comment_id == this.dataObj.comment_id);
      this.selectedPostComment = item ? item.value : undefined;
    }
  }
  public onCountryChange(event) {
    this.dataObj.country_id = (event.value as Country).country_id;
  }
  public onPostCommentChange(event) {
    this.dataObj.parent_id = (event.value as PostComments).comment_id;
  }

  public onPostChange(event) {
    this.dataObj.post_id = (event.value as Posts).post_id;
  }

  public onUserChange(event) {
    this.dataObj.user_id = (event.value as User).user_id;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.comment_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: PostComments) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: PostComments) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: PostComments) {
    this.dataService.updateStatus(obj);
  }
}
