import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import {
  GridHeaderEx,
  GridHeader,
  Blogsetup,
  defaultBlogsetup,
  defaultMprocess,
  BlogComments,
  Tags,
  BlogTagItem,
  defaultBlogTagItem,
  User,
  Authors
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  BlogsetupService,
  AppConfigService
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

// import { NgxSuneditorComponent } from 'projects/ngx-suneditor/src/public-api';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent, FocusEvent, BlurEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import Adapter from './ckeditorAdapter';

// import suneditor from 'suneditor';
// import plugins from 'suneditor/src/plugins';
@Component({
  selector: 'app-blogsetup',
  templateUrl: './blogsetup.component.html',
  styleUrls: ['./blogsetup.component.scss']
})
export class BlogsetupComponent implements OnInit, OnDestroy {
  @ViewChild('blog_attach', { static: true }) blogattach: ElementRef;
  // @ViewChild(NgxSuneditorComponent) editor: NgxSuneditorComponent;
  //private ngxSunEditor: NgxSuneditorComponent;
  
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<any>;
  public itemDataArray: Array<any> = [];
  public itemmparams: Array<any> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Blogsetup = defaultBlogsetup;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public isBookmarkDetails = false;

  public itemObj: Blogsetup = defaultBlogsetup;

  public itemTagDataArray: Array<any> = [];
  public BlogTagItemObj: BlogTagItem = defaultBlogTagItem;
  public tagList: Array<SelectItem> = [];
  public selectedTag: Array<Tags> = [];

  public blogcommentsList: Array<SelectItem> = [];
  public selectedBlogComment = 0;

  public userList: Array<SelectItem> = [];
  public selectedUser: User;

  public authorsList: Array<SelectItem> = [];
  public selectedAuthor: Authors;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : this.isBookmarkDetails ? 'Bookmarks ': (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }
  public url : string | ArrayBuffer;

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'blog_title', header: 'Blog Title', isVisible: true, isDefault: true },
    { field: 'short_desc', header: 'Blog Short Descriprtion', isVisible: true, isDefault: true },
    { field: 'long_desc', header: 'Long Description', isVisible: true, isDefault: true },
    { field: 'blog_attach', header: 'Attachment', isVisible: true, isDefault: true },
    { field: 'doc_type', header: 'Document Type', isVisible: false, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public itemCols = [
    { field: 's_no', header: 'S No' },
    { field: 'parent_comment', header: 'Parent Comment' },
    { field: 'comment_desc', header: 'Comment Description' },
    { field: 'author_name', header: 'Author' }
  ];

  public bookmarkCols = [
    { field: 's_no', header: 'S No' },
    // { field: 'parent_comment', header: 'Parent Comment' },
    { field: 'user_fname', header: 'User First Name' },
    { field: 'user_lname', header: 'User Last Name' }
  ];

  public itemTagCols = [
    { field: 's_no', header: 'S No' },
    { field: 'tag_id', header: 'Tags' }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: BlogsetupService,
    public util: UtilService,
    private config: AppConfigService
  ) {
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }
 
  public Editor = ClassicEditor;
  public componentEvents: string[] = [];
  ckconfig: any;
  public isDisabled = false;

  customAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new Adapter(loader, editor.config);
    };
  }

  toggleDisableEditors() {
    this.isDisabled = !this.isDisabled;
  }

  onChange(event: ChangeEvent): void {
    console.log(event.editor.getData());
    this.componentEvents.push('Editor model changed.');
  }

  onFocus(event: FocusEvent): void {
    this.componentEvents.push('Focused the editing view.');
  }

  onBlur(event: BlurEvent): void {
    this.componentEvents.push('Blurred the editing view.');
  }

  onReady(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new Adapter(loader, editor.config);;
    };
  }

  public editorData =
  `<p>test image upload</p>`;

  ngOnInit() {

    this.ckconfig = {
      // include any other configuration you want
      extraPlugins: [this.customAdapterPlugin],
      image: {
        styles: ['full', 'side', 'alignLeft', 'alignRight'],
        toolbar: [ 'imageStyle:full', 'imageStyle:side', 'imageStyle:alignRight', 'imageStyle:alignLeft', '|', 'imageTextAlternative' ]
      }
    };
 

   // this.ngxSunEditor.enabled();
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.loadDropDownLists();

   

    this.dataService.getUsers()
    .subscribe(data => {
      this.userList = data.map(ele => {
        return { label: ele.user_fname, value: ele };
      });
    });

    this.dataService.getauthors()
    .subscribe(data => {
      this.authorsList = data.map(ele => {
        return { label: ele.author_name, value: ele };
      });
    });
  }
  // ngAfterViewInit():void{
  //     const initEditor = suneditor.init({
  //       plugins: plugins,
  //     //  height: 200,
  //       buttonList: [
  //           [
  //           'undo', 'redo',
  //           'font', 'fontSize', 'formatBlock',
  //           'paragraphStyle', 'blockquote',
  //           'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
  //           'fontColor', 'hiliteColor', 'textStyle',
  //           'removeFormat',
  //           'outdent', 'indent',
  //           'align', 'horizontalRule', 'list', 'lineHeight',
  //           'table', 'link', 'image', 'video', 'audio', /** 'math', */ // You must add the 'katex' library at options to use the 'math' plugin.
  //           /** 'imageGallery', */ // You must add the "imageGalleryUrl".
  //           'fullScreen', 'showBlocks', 'codeView',
  //           'preview', 'print', 'save', 'template',
  //           /** 'dir', 'dir_ltr', 'dir_rtl' */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
  //           ]
  //       ]
  //   });
  //   initEditor.create('sample_2', {
  //       // The value of the option argument put in the "create" function call takes precedence
  //       height: 'auto',
  //       buttonList: [
  //           ['bold', 'underline', 'italic'],
  //           ['removeFormat'],
  //           ['preview', 'print']
  //       ]
  //   });
  // }
  // showView: boolean = false;

  // title = 'angular-suneditor';

  // toggleView() {
  //   this.showView = !this.showView;
  // }

  // testEventListener(event?: any) {
  //   console.log('event was fired');
  //   console.log(event);
  // }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // public fileUpload(event) {
	// 	const file = event.target.files;
  //   console.log(file.length);
  //   for (var i = 0; i < event.target.files.length; i++) { 
  //     //this.dataObj.blog_attach.push(event.target.files[i]);
  //     console.log(event.target.files[i]);
  //     this.dataObj.blog_attach.push(event.target.files[i]);
  //   }
	// 	//this.dataObj.blog_attach = file.FileList;
	// }

  public fileUpload(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log(event.target.result);
      }
      const file = event.target.files[0];
      this.dataObj.blog_attach = file;
    }
  }

  public viewImage(file: string) {
    return this.config.ipAddress + 'uploads/blog_attach/' + file;
  }

  public onBlogCommentChange(event) {
    this.itemObj.parent_id = event.value.comment_id;
    this.itemObj.parent_comment = (event.value as BlogComments).comment_desc;
  }

  public onUserChange(event) {
    this.itemObj.user_id = (event.value as User).user_id;
    this.itemObj.user_fname = (event.value as User).user_fname;
  }

  public onAuthorChange(event) {
    this.itemObj.author_id = (event.value as Authors).author_id;
    this.itemObj.author_name = (event.value as Authors).author_name;
  }

  private loadDropDownLists() {

    this.dataService.getTags()
    .subscribe(data => {
      this.tagList = data.map(ele => {
        return { label: ele.tag_name, value: ele.tag_id };
      });
    });

    this.dataService.getBlogComments(this.dataObj.blog_id)
    .subscribe(data => {
      this.blogcommentsList = data.map(ele => {
        return { label: ele.comment_desc, value: ele };
      });
    });
  }

  public onTagChange(event) {
    console.log(event);
    if (this.selectedTag) {
      console.log(this.selectedTag);
      const ids = [];
      this.selectedTag.forEach(e => {
        ids.push(e);
      });
      this.dataObj.tag_id = ids.join(',');
      console.log(this.dataObj.tag_id);
      //console.log(this.tagitemObj.tag_id.split(','));
    }
  }

  public addToItemList() {
     
    console.log(this.itemObj);
    this.itemObj.blog_id = this.dataObj.blog_id;
    this.dataService.updatecommentData(this.itemObj).subscribe((data) => {
     // this.bindDropDownList();
      this.loadDropDownLists();
    });
 
   // this.isEditDetails = false;
    const tmp = Object.assign({}, this.itemObj);
    tmp.s_no = this.itemDataArray.length + 1;
    const list = [... this.itemDataArray];
    list.push(tmp);
    this.itemDataArray = list;
     
     this.itemObj = Object.assign({}, defaultBlogsetup);
     this.itemObj.comment_desc = '';
     this.selectedBlogComment = 0;
     this.selectedAuthor = undefined;
  }

  public addToBlogTagItemList() {
     
    // this.itemObj.parent_id = this.selectedpostComment;
    // this.itemObj.user_fname = this.selectedUser.user_fname;
  
     const tmp = Object.assign({}, this.BlogTagItemObj);

     tmp.s_no = this.itemTagDataArray.length + 1;
     const list = [... this.itemTagDataArray];
     list.push(tmp);
     this.itemTagDataArray = list;

     console.log(this.itemTagDataArray);
     this.selectedTag = [];
     
   
 }

  public deleteItemRow(event) {
  console.log(event);
  this.itemObj.comment_id = event.comment_id;
  console.log(this.itemObj.comment_id);
  this.dataService.deleteData(this.itemObj).subscribe((data) => {
  // this.bindDropDownList();
    this.loadDropDownLists();
  });
    this.itemDataArray.splice(
      this.itemDataArray.findIndex(e => e.pcstepId === event.pcstepId), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  public deleteBlogTagItemRow(event) {
    this.itemTagDataArray.splice(
      this.itemTagDataArray.findIndex(e => e.blog_id === event.tag_id), 1);
    this.itemTagDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  public updateData() {
    console.log(this.dataObj);
    this.dataObj.items = this.itemDataArray;
    this.dataObj.blogtagitems = this.itemTagDataArray;
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
    this.isEditDetails = false;
  }

  public updateStatus(obj: Blogsetup) {
    this.dataService.updateStatus(obj);
  }

  public viewData(obj: Blogsetup) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public viewBookmarkData(obj: Blogsetup) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isBookmarkDetails = true;
    
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultBlogsetup);

    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
      this.loadDropDownLists();
    }
  }

  private bindDropDownList() {
   
    this.dataService.getItemsList(this.dataObj.blog_id)
    .subscribe(dataList => {
      this.itemDataArray = dataList.map((ele, indx) => {
        // tslint:disable-next-line: no-string-literal
        ele['s_no'] = indx + 1;
       // ele['mparams'] = else.mparams;
        
        return ele;
      });
    });

    this.dataService.getBlogComments(this.dataObj.blog_id)
    .subscribe(data => {
      this.blogcommentsList = data.map(ele => {
        return { label: ele.comment_desc, value: ele };
      });
    });

    this.dataService.getBookmarksList(this.dataObj.blog_id)
    .subscribe(dataList => {
      this.itemmparams = dataList.map((ele, indx) => {
        // tslint:disable-next-line: no-string-literal
        ele['s_no'] = indx + 1;
       // ele['mparams'] = else.mparams;
        
        return ele;
      });
    });

      const idList = this.dataObj.tag_id ? this.dataObj.tag_id.split(',') : [];
      console.log(idList);
      //console.log(this.tagList);
      const items = [];
      for (const val of idList) {
        // tslint:disable-next-line: triple-equals disable-next-line: no-string-literal
        const item = this.tagList.find(e => e['value'] == val);
        // tslint:disable-next-line: no-string-literal
        if (item) { items.push(item['value']); }
      }
      this.selectedTag = items;

      console.log(this.selectedTag);
     
  }

  public deleteData(obj: Blogsetup) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.blog_id === 0 ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    this.dataObj.items = this.itemDataArray;
    this.dataObj.blogtagitems = this.itemTagDataArray;
    this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    this.isAddDetails = false;
    
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.itemDataArray = [];
    this.url = undefined;
    this.selectedTag = undefined;
    this.dataService.getDataRecords();
    // TODO: reset all the selected items.
    // this.dataObj = Object.assign({}, defaultBlogsetup);
    
  }

  public viewPdf(file: string) {
    return this.config.ipAddress + '/pharma/uploads/specs_doc/' + file;
  }

}
