import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsgService, AdminMenuService } from 'src/app/service';
import { AdminMenu, GridHeader, GridHeaderEx, MasterActions, defaultAdminMenu } from '../../../model';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})

export class AdminMenuComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public adminMenuArray: Array<AdminMenu>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public adminMenu: AdminMenu = defaultAdminMenu;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editAdminMenuList: any = {};
  public selectedAdminMenu: AdminMenu;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  private get pageName() {
    return this.adminMenuService.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'menuDesc', header: 'Description', isVisible: true, isDefault: true },
    { field: 'menuCode', header: 'Code', isVisible: true, isDefault: true },
    // { field: 'menuType', header: 'Menu Type', isVisible: true, isDefault: true },
    { field: 'parentMenu', header: 'Parent Menu', isVisible: true, isDefault: true },
    { field: 'menuLink', header: 'Menu Link', isVisible: true, isDefault: true },
    { field: 'menuIsActive', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public adminMenuList: Array<SelectItem> = [];
  public parentMenuList: Array<SelectItem> = [];

  constructor(
    private adminMenuService: AdminMenuService,
    private msgSvc: MsgService
  ) {
    // For adminMenu dropdown.
    const amList = this.adminMenuService.adminMenuList.subscribe((data) => {
      this.adminMenuList = [];
      if (data.length) {
        // tslint:disable-next-line: triple-equals
        this.adminMenuList = data.filter(e => e.menuIsActive && e.menuParentId == 0).map(ele => {
          return { label: ele.menuDesc, value: ele };
        });
      } else {
        this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit for AdminMenu List' });
      }
    });
    this.subscription.add(amList);
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.adminMenuService.getDataRecords();

    const aList = this.adminMenuService.adminMenuList.subscribe((data) => {
      this.adminMenuArray = [];
      if (data.length) {
        this.adminMenuArray = data.map((e, indx) => {
          e.s_no = indx + 1;
          // tslint:disable-next-line: triple-equals
          const ele = data.filter(i => e.menuParentId == i.menuID)[0];
          // tslint:disable-next-line: no-string-literal
          e['parentMenu'] = ele ? ele.menuDesc : 'N/A';
          return e;
        });
        // tslint:disable-next-line: triple-equals
        this.parentMenuList = this.adminMenuArray.filter(e => e.menuParentId == 0).map(i => {
          return { label: i.menuDesc, value: i.menuID };
        });
      } else {
        this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
      }
    });
    this.subscription.add(aList);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.adminMenu = Object.assign({}, event ? event : defaultAdminMenu);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      const selVal = this.parentMenuList.find(e => e.value === event.menuParentId);
      this.selectedAdminMenu = selVal ? selVal.value : undefined;
      this.isEditDetails = true;
    }
  }

  public cancelEditDlg() {
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.isViewDetails = false;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.adminMenuService.addData(this.adminMenu).subscribe((data) => {
        this.adminMenuService.getDataRecords();
      }, err => {
        this.msgSvc.addErrorMsg(err);
      });
    } else {
      this.adminMenuService.updateData(this.adminMenu).subscribe((data) => {
        this.adminMenuService.getDataRecords();
      });
    }
    this.isEditShow = false;
  }

  public deleteData(adminMenu: AdminMenu) {
    this.isDeleteDetails = true;
    this.adminMenu = adminMenu;
  }

  public viewData(adminMenu: AdminMenu) {
    this.isViewDetails = true;
    this.adminMenu = adminMenu;
  }

  public deleteDetails() {
    const dataObj = { menuId: this.adminMenu.menuID };
    this.adminMenuService.deleteData(dataObj).subscribe((data) => {
      this.adminMenuService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public onAdminMenuChange(event) {
    if (event) {
      this.adminMenu.menuParentId = event.value;
    }
  }

  public updateStatus(adminMenu: AdminMenu) {
    this.adminMenuService.updateStatus(adminMenu);
  }
}
