export interface State {
    s_no?: number;
    state_id: number;
    state_code: string;
    state_desc: string;
    country_id: number;
    status: boolean;
}

export const defaultState = {
    state_id: 0,
    country_id: 0,
    state_code: '',
    state_desc: '',
    status: false
};
