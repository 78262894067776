export interface PostComments {
    s_no?: number;
    comment_id: number;
    post_id: number;
    blog_id: number;
    user_id: number;
    comment_desc:string;
    country_id: number;
    status: boolean;
    parent_id: number;
}

export const defaultPostComments = {
    comment_id: 0,
    country_id: 0,
    post_id: 0,
    blog_id: 0,
    user_id:0,
    comment_desc:'',
    parent_id: 0,
    status: false
};
