export interface AdminMenu {
    Id: any;
    s_no?: number;
    menuParentId: number;
    menuPosition: number;
    menuID: number;
    menuCode: string;
    menuDesc: string;
    menuLink: string;
    menuIcon: string;
    menuType: string;
    menuIsActive: number;
    status?: number;
}

export const defaultAdminMenu: AdminMenu = {
    menuParentId: 0,
    menuPosition: 0,
    menuID: 0,
    Id: 0,
    menuCode: '',
    menuDesc: '',
    menuLink: '',
    menuIcon: '',
    menuType: '',
    menuIsActive: 0
};
