import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import {
  GridHeader,
  Category,
  Country,
  State,
  defaultCategory,
  GridHeaderEx 
} from 'src/app/model';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import {
  MsgService
  , CategoryService
  , UtilService 
  , AppConfigService
  , UserType
} from 'src/app/service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {
  @ViewChild('catg_images', { static: true }) specDoc: ElementRef;
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Category>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Category = defaultCategory;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public categoryList: Array<SelectItem> = [];
  public selectedCategory: Category;

  public equipmentAvailablecheck: boolean = false;
  uploadedFiles: any[] = [];
  uploadedattchments: any[] = [];

  public stateList: Array<SelectItem> = [];
  public selectedState: Country;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }
  public url : string | ArrayBuffer;
  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isAddShow() {
    return this.isAddDetails;
  }

  public set isAddShow(value: boolean) {
    this.isAddDetails = value;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	{ field: 'catg_id', header: 'Id', isVisible: false, isDefault: true },
    // { field: 'country_code', header: 'Country', isVisible: true, isDefault: true },
    { field: 'parent_name', header: 'Parent Category', isVisible: true, isDefault: true },
    { field: 'catg_images', header: 'Images', isVisible: true, isDefault: true },
    { field: 'catg_name', header: 'Category Name', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private config: AppConfigService,
    private dataService: CategoryService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
      // this.categoryList = this.dataArray.map(ele => {
      //       return { label: ele.catg_name, value: ele.catg_id };
      //     });

      this.dataService.getcategories()
      .subscribe(data => {
        this.categoryList = data.map(ele => {
          return { label: ele.catg_name, value: ele };
        });
      });
  }
 
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultCategory);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
	   this.bindDropDownList();
    }
  } 
  private bindDropDownList() {

    if (this.dataObj.catg_id) {
      //console.log(this.dataObj.catg_id);
      const item = this.categoryList
      
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.catg_id == this.dataObj.parent_id);
        //console.log(item);
      this.selectedCategory = item ? item.value : undefined;
    }
    if (this.dataObj.state_id) {
      const item = this.stateList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.state_id == this.dataObj.state_id);
      this.selectedState = item ? item.value : undefined;
    }
  }
  public oncategoryChange(event) {
    this.dataObj.parent_id = (event.value as Category).catg_id;
  }
  public onStateChange(event) {
    this.dataObj.state_id = (event.value as State).state_id;
  }

  
	public fileUpload(event) {
		// const file = event.target.files[0];
		// this.dataObj.catg_images = file;
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log(event.target.result);
      }
      const file = event.target.files[0];
      this.dataObj.catg_images = file;
    }
	}
  
  public viewImage(file: string) {
    return this.config.ipAddress + 'uploads/catg_images/' + file;
  }

  public viewPdf(file: string) {
    return this.config.ipAddress + 'uploads/catg_images/' + file;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
    this.url = undefined;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.url = undefined;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.catg_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Category) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Category) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: Category) {
    this.dataService.updateStatus(obj);
  }
}
