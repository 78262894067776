import { Component, OnInit, NgModule } from '@angular/core';
import { Title, BrowserModule } from '@angular/platform-browser';
import { PrimeIcons } from "primeng/api";
import {
  AppConfigService
  , BusyCursorService
  , AuthenticationService
  , LeftMenuService,
  MenuItem,
  defaultCSS
} from './service';
import { Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { Calendar } from '@fullcalendar/core';
/** Dynamic js required for UI actions. */
const url = 'assets/js/app.min.4fc8dd6e.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private pageName = '';
  public doReset = false;
  public showProgress = false;
  public dateTimeNow = '';
  public userName = '';
  public userEmail = '';
  public userRoleName = '';
  public currentPage = 'Home';
  public menus: Array<MenuItem> = [];
  public notificationCount = 0;
  public showNotifications = false;
  public hasNotifications = false;


  public get isLogin() {
    return this.pageName === 'login';
  }

  public menuToggleCSS = defaultCSS;
  activityList: any[];
  constructor(
    private title: Title,
    private router: Router,
    private busySvc: BusyCursorService,
    private authSvc: AuthenticationService,
    private menuSvc: LeftMenuService,
    public config: AppConfigService) {

    this.title.setTitle(config.title);


    //console.log(this.authSvc);
    this.busySvc.isShowBusy.subscribe((value) => {
      this.showProgress = value;

    });

    this.router.events.subscribe(
      (event: NavigationEvent) => {
        if (this.pageName !== 'login') {
          if (event instanceof NavigationStart && event.url === '/login') {
            this.pageName = 'login';
          }
        }
      });

    this.menuSvc.toggleMenu().subscribe(val => {
      this.menuToggleCSS = val;
    });

    this.menuSvc.toggleMenu().subscribe(val => {
      this.menuToggleCSS = val;
    });


    // // TODO: Remove
    //this.loadMenus(null);

    this.menuSvc.menus.subscribe(menuData => {

      this.loadMenus(menuData);
    });
  }

  ngOnInit(): void {
    this.pageName = 'login';
    this.dateTimeNowEvent();


  }

  private dateTimeNowEvent() {
    setInterval(() => {
      this.dateTimeNow = new Date().toLocaleString('en-GB');
    }, 1000);
  }



  public loadScript() {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  public loadMenus(menuData: Array<MenuItem>) {
    const tmpMenus = [];
    console.log(menuData);
    this.menus = tmpMenus;
    this.runScript();
  }

  configone = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'my-cool-menu-item',
    selectedListFontColor: `red`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    useDividers: true,
    rtlLayout: false
  };
  public runScript() {
    const loadAPI = new Promise(resolve => { this.loadScript(); });
  }

  public loginAction(event) {
    this.pageName = 'home';
    this.menuSvc.getMenus();
    this.userName = localStorage.getItem('userName');
    this.userEmail = localStorage.getItem('userEmail');
    this.userRoleName = localStorage.getItem('userrolename');
    this.router.navigate(['/home']);
  }

  public logout() {
    this.router.navigate(['/login']);
    this.pageName = 'login';
    setTimeout(() => location.reload(), 100);
  }

  public menuItemSelect(menu) {
    this.currentPage = menu;
  }

  public gotoHome() {
    this.router.navigate(['/home']);
    this.currentPage = 'Dashboard';
  }

  public showNotificationsDlg() {
    this.showNotifications = true && (this.notificationCount > 0);
  }
  appitems = [
    {
      label: 'Admin',
      faIcon: 'fa fa-arrow-circle-right',
      items: [
        { label: 'Users', link: 'user', faIcon: 'fa fa-angle-double-right' },
        { label: 'Main Menu', link: 'admin-menu', faIcon: 'fa fa-angle-double-right' },
        // { label: 'Sub Menu', link: 'home' },
        { label: 'User Roles', link: 'user-roles', faIcon: 'fa fa-angle-double-right' },
      ]
    },
  ];

}
