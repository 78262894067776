export interface ProductAttachments {
    s_no?: number;
    productMasterId: number;
    productMasterCode: string;
    productMasterName: string;
    status: number;
    productCategoryId: number;
    productGradeId: number;
    pharmaCopiId: number;
    productUOM: number;
    productSpecDoc: File | string;
	productMsdsDoc: File | string;
    productForm: number;
	productForm_id: number;
    productCategoryDesc?: string | undefined;
	productFormdesc?: string | undefined;
    pharmaCopiDesc?: string | undefined;
    productGrade?: string | undefined;
    pharmaDesc?: string | undefined;
	// items: Array<INTItem>;

 
    prod_code: string;
    prod_desc: string;
    catg_id: number;
    author_id: number;
    variant_id: number;
    rec_status: number;
    trending: number;
    popular: number;
 

    attach_id: number;
    prod_id: number;
    attach_name: File | string;
    attach_desc: string;
    attach_type: string;
    attach_type_id: number;
    attach_url: string;
    doc_type: string;

}

export const defaultProductAttachments = {
    productMasterId: 0,
    productMasterCode: '',
    productMasterName: '',
    productCategoryId: 0,
    productGradeId: 0,
    pharmaCopiId: 0,
    productUOM: 0,
    productSpecDoc: '',
	productMsdsDoc: '',
    pharmaCopiDesc: '',
    productForm: 0,
	productForm_id: 0,
    status: 0,

    prod_code: '',
    prod_desc: '',
    catg_id: 0,
    author_id: 0,
    variant_id: 0,
    rec_status: 0,
    popular: 0,
    trending: 0,

    attach_id: 0,
    prod_id: 0,
    attach_name: '',
    attach_desc: '',
    attach_type: '',
    attach_type_id: 0,
    attach_url: '',
    doc_type: ''

	// items: []
};
// export interface INTItem {
//     s_no?: number;
//     INTproductMasterCode: string;
//     INTproductMasterName: string;
//     INTproductMasterMake: string;
// }

// export const defaultINTItem: INTItem = {
//     INTproductMasterCode: '',
//     INTproductMasterName: '',
//     INTproductMasterMake: ''
// };
