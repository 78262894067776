export interface Likes {
    s_no?: number;
    likes_id: number;
    slike_id: number;
    slike_type:string;
     status: boolean;
}

export const defaultLikes = {
    likes_id: 0,
    slike_id: 0,
    slike_type: '',
    status: false
};
