export interface deleteUserDetails {
    fname: string;
    lname: string;
    email: string;
    phone: string;
    age: number;
    city: string;
}

export const defaultdeleteUserDetails: deleteUserDetails = {
    fname: '',
    lname: '',
    email: '',
    phone: '',
    age: 0,
    city: ''
};