export interface Dataimport {
    s_no?: number;
    country_id: number;
    country_code: string;
    country_name: string;
    status: boolean;
}

export const defaultDataimport = {
    country_id: 0,
    country_code: '',
    country_name: '',
    status: false
};
