import { Component, OnInit, OnDestroy } from '@angular/core';
import { MasterActions, GridHeader, BlogComments, defaultBlogComments, GridHeaderEx, Country, User, Blogs } from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, BlogCommentsService,UserType,UtilService } from 'src/app/service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-blogcomments',
  templateUrl: './blogcomments.component.html',
  styleUrls: ['./blogcomments.component.scss']
})
export class BlogCommentsComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<BlogComments>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: BlogComments = defaultBlogComments;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public countryList: Array<SelectItem> = [];
  public selectedCountry: Country;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public blogcommentsList: Array<SelectItem> = [];
  public selectedBlogComment: BlogComments;

  public blogList: Array<SelectItem> = [];
  public selectedBlog: Blogs;

  public userList: Array<SelectItem> = [];
  public selectedUser: User;

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	  { field: 'comments_id', header: 'Id', isVisible: false, isDefault: true },
    // { field: 'Post_id', header: 'Post', isVisible: true, isDefault: true },
    { field: 'blog_title', header: 'Blog ', isVisible: true, isDefault: true },
    { field: 'user_fname', header: 'User', isVisible: true, isDefault: true },
    { field: 'comment_desc', header: 'Comment Description', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private dataService: BlogCommentsService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
      this.dataService.getCountrys()
      .subscribe(data => {
        this.countryList = data.map(ele => {
          return { label: ele.country_name, value: ele };
        });
      });
      this.dataService.getUsers()
      .subscribe(data => {
        this.userList = data.map(ele => {
          return { label: ele.user_fname, value: ele };
        });
      });
      this.dataService.getBlogs()
      .subscribe(data => {
        this.blogList = data.map(ele => {
          return { label: ele.blog_title, value: ele };
        });
      });
      this.dataService.getBlogComments()
      .subscribe(data => {
        this.blogcommentsList = data.map(ele => {
          return { label: ele.comment_desc, value: ele };
        });
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultBlogComments);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
	   this.bindDropDownList();
    }
  }
  private bindDropDownList() {

    if (this.dataObj.country_id) {
      const item = this.countryList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.country_id == this.dataObj.country_id);
      this.selectedCountry = item ? item.value : undefined;
    }
    if (this.dataObj.user_id) {
      const item = this.userList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.user_id == this.dataObj.user_id);
      this.selectedUser = item ? item.value : undefined;
    }
    if (this.dataObj.blog_id) {
      const item = this.blogList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.blog_id == this.dataObj.blog_id);
      this.selectedBlog = item ? item.value : undefined;
    }
    if (this.dataObj.comment_id) {
      const item = this.blogcommentsList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.comment_id == this.dataObj.comment_id);
      this.selectedBlogComment = item ? item.value : undefined;
    }
  }
  public onCountryChange(event) {
    this.dataObj.country_id = (event.value as Country).country_id;
  }
  public onBlogCommentChange(event) {
    this.dataObj.parent_id = (event.value as BlogComments).comment_id;
  }

  public onBlogChange(event) {
    this.dataObj.blog_id = (event.value as Blogs).blog_id;
  }

  public onUserChange(event) {
    this.dataObj.user_id = (event.value as User).user_id;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.comment_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: BlogComments) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: BlogComments) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: BlogComments) {
    this.dataService.updateStatus(obj);
  }
}
