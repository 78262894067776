import { Component, OnInit, OnDestroy } from '@angular/core';
import { MasterActions, GridHeader, Docsharerequest, defaultDocsharerequest, GridHeaderEx } from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, DocsharerequestService,UserType,UtilService } from 'src/app/service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-docsharerequest',
  templateUrl: './docsharerequest.component.html',
  styleUrls: ['./docsharerequest.component.scss']
})
export class DocsharerequestComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Docsharerequest>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Docsharerequest = defaultDocsharerequest;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};
  public itemDataArray: Array<any> = [];
  public DocsharerequestList: Array<SelectItem> = [];
  public selectedDocsharerequest: Docsharerequest;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	  { field: 'd_id', header: 'Id', isVisible: false, isDefault: true },
    { field: 'user_fname', header: 'User Name ', isVisible: true, isDefault: true },
    { field: 'user_login', header: 'Email', isVisible: true, isDefault: true },
    { field: 'fileshare_doc', header: 'File', isVisible: true, isDefault: true },
    { field: 'prod_name', header: 'Intrested Product', isVisible: true, isDefault: true },
    { field: 'created_on', header: 'Created On', isVisible: true, isDefault: true },
    { field: 'status', header: 'Approval', isVisible: true, isDefault: true },
  ];


  public itemCols = [
    { field: 's_no', header: 'S No' },
    { field: 'prod_code', header: 'Product Name' },
    { field: 'created_on', header: 'Created On' }
  ];
  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private dataService: DocsharerequestService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    
      this.subscription.add(
        this.dataService.singledataList.subscribe((data) => {
          this.itemDataArray = [];
          if (data.length) {
            this.itemDataArray = data.map((e, indx) => {
              e.s_no = indx + 1;
              return e;
            });
          } else {
            this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
          }
        }));
      // this.dataService.getDocsharerequest()
      // .subscribe(data => {
      //   this.DocsharerequestList = data.map(ele => {
      //     return { label: ele.Docsharerequest_name, value: ele };
      //   });
      // });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultDocsharerequest);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
	   this.bindDropDownList();
    }
  }
  private bindDropDownList() {

    if (this.dataObj.d_id) {
      const item = this.DocsharerequestList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.d_id == this.dataObj.d_id);
      this.selectedDocsharerequest = item ? item.value : undefined;
    }
  }
  public onDocsharerequestChange(event) {
    this.dataObj.d_id = (event.value as Docsharerequest).d_id;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.d_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Docsharerequest) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Docsharerequest) {
    this.dataObj = obj;  
    // this.dataService.getDataRecordsbyid(this.dataObj.d_id);
    this.isViewDetails = true;
  }

  public updateStatus(obj: Docsharerequest) {
    this.dataService.updateStatus(obj);
  }
}
