import { Injectable } from '@angular/core';
import { ApiActions } from './util.service';
import { Subject } from 'rxjs';
import { Banner, ProductMaster, Variants, User } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { MsgService } from './msg.service';
import { CommonDataService } from './common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class BannerService {

  public pageName = 'Banner';
  public dataList = new Subject<Array<Banner>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.BannerApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Banner> = [];
          response.result.Banner.map(element => {
            element.status = element.status + '' === '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getProducts(): Subject<Array<ProductMaster>> {
    const url = this.config.ipAddress + this.config.ProductMasterApi[ApiActions.Get];
    const dataArray: Array<ProductMaster> = [];
    const data = new Subject<Array<ProductMaster>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Products.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getVariants(id: number): Subject<Array<Banner>> {
    const url = this.config.ipAddress + this.config.BannerApi[ApiActions.Delete];
    const dataArray: Array<Banner> = [];
    const data = new Subject<Array<Banner>>();
    const fData = new FormData();
    fData.append('type_id', id + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Banner.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getUsers(): Subject<Array<User>> {
    const url = this.config.ipAddress + this.config.userApi[ApiActions.Get];
    const dataArray: Array<User> = [];
    const data = new Subject<Array<User>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.userslist.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public addData(objData: Banner) {
    const fData = new FormData();
    fData.append('banner_id', objData.banner_id + '');
    fData.append('type_id', objData.type_id + '');
    fData.append('banner_image', objData.banner_image);
    // fData.append('user_id', objData.user_id + '');
    // fData.append('prod_id', objData.prod_id + '');
    fData.append('variant_id', objData.variant_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  public updateData(objData: Banner) {
    const fData = new FormData();
    fData.append('banner_id', objData.banner_id + '');
    fData.append('type_id', objData.type_id + '');
    fData.append('banner_image', objData.banner_image);
   // fData.append('user_id', objData.user_id + '');
    //fData.append('prod_id', objData.prod_id + '');
    fData.append('variant_id', objData.variant_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('Banner_id', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Banner) {
    const fData = new FormData();
    fData.append('status', objData.status ? '1' : '0');
    fData.append('banner_id', objData.banner_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }
}
