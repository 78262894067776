import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import {
  GridHeader,
  ProductMaster,
  Country,
  Authors,
  Variants,
  defaultProductMaster,
  defaultVariants,
  defaultReview,
  GridHeaderEx, 
  Category,
  Tags,
  Review
} from 'src/app/model';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import {
  MsgService
  , ProductMasterService
  , UtilService
  , UserType
  , AppConfigService
} from 'src/app/service';


@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.scss']
})
export class ProductMasterComponent implements OnInit, OnDestroy {
  @ViewChild('prod_image', { static: true }) specDoc: ElementRef;
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<ProductMaster>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: ProductMaster = defaultProductMaster;
  public itemDataArray:Array<any> = [];
  public reviewitemDataArray:Array<any> = [];
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isReviewAddDetails = false;
  public isEditDetails = false;
  public isReviewDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public trending: boolean = false;
  public popular: boolean = false;
  public itemObj: Variants = Object.assign({}, defaultVariants);
  public reviewitemObj: Review = Object.assign({}, defaultReview);
  public ProductMasterList: Array<SelectItem> = [];
  public selectedCountry: Country;

  public variantList: Array<SelectItem> = [];
  public selectedVariant: Variants;

  public authorsList: Array<SelectItem> = [];
  public selectedAuthor: Authors;

  public categoriesList: Array<SelectItem> = [];
  public selectedCategory: Category;

  public tagList: Array<SelectItem> = [];
  public selectedTag: Array<Tags> = [];

  public trendingList: Array<SelectItem> = [];
  public selectedTrending: ProductMaster;

  public popularList: Array<SelectItem> = [];
  public selectedPopular: ProductMaster;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }
public modelattachments = [];
public editattachmentsdata:any;

  public set isReviewShow(value: boolean) {
    this.isReviewDetails = value;
    this.isReviewAddDetails = value;
  }

  public set isAttachmentShow(value: boolean) {
    this.isReviewDetails = value;
    this.isReviewAddDetails = value;
  }


  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isReviewShow() {
    return this.isReviewDetails || this.isReviewAddDetails;
  }

  public get isAttachmentShow() {
    return this.isReviewDetails || this.isReviewAddDetails;
  }

  uploadedFiles: any[] = [];
  uploadedattchments: any[] = [];

  edituploadedFiles: Array<any> = [];
  edituploadedattchments: any[] = [];

  public get isAddShow() {
    return this.isAddDetails;
  }

  public set isAddShow(value: boolean) {
    this.isAddDetails = value;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : this.isReviewDetails ? 'Review ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }
public visible: boolean;
public attachviewvisible: boolean;
public documentattachvisible: boolean;



public get attachdlgHeader() {
  return 'Edit / Add Attchments'
}

  public url : string | ArrayBuffer;

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'prod_id', header: 'Id', isVisible: false, isDefault: true },
    // { field: 'country_code', header: 'Country', isVisible: true, isDefault: true },
    { field: 'prod_name', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'prod_desc', header: 'Product Description', isVisible: true, isDefault: true },
    { field: 'prod_image', header: 'Product Image', isVisible: true, isDefault: true },
    { field: 'catg_name', header: 'Category', isVisible: true, isDefault: true },
    { field: 'variants_count', header: 'Variant Count', isVisible: true, isDefault: true },
    { field: 'attachments', header: 'Document', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];
  public itemCols: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'variant_code', header: 'Varint Code', isVisible: true, isDefault: true },
    { field: 'variant_name', header: 'Varint Name', isVisible: true, isDefault: true },
    { field: 'attachments_count', header: 'Varint Attachments Count', isVisible: true, isDefault: true },
    { field: 'attachments', header: 'Varint Attachments', isVisible: true, isDefault: true },
  ];
  public reviewitemCols: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'review', header: 'Product Review', isVisible: true, isDefault: true },
    { field: 'rating', header: 'Product Rating', isVisible: true, isDefault: true },
  ];
  public get pageName() {
    return this.dataService.pageName;
  }
  
 // public images: any[];

  public responsiveOptions: any[] = [
      {
          breakpoint: '1500px',
          numVisible: 5
      },
      {
          breakpoint: '1024px',
          numVisible: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];

  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private config: AppConfigService,
    private dataService: ProductMasterService
  ) { }
  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.uploadedattchments = this.uploadedFiles;
    console.log(this.uploadedattchments);
    this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
  }

  public onFileChange(event:any) {
    console.log(event);
    if (event.target.files && event.target.files) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
    
                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.uploadedFiles.push(event.target.result); 
    
                  //  this.myForm.patchValue({
                  //     fileSource: this.images
                  //  });
                }
   
                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }
     
  // submit(){
  //   //console.log(this.myForm.value);
  //   this.http.post('http://localhost:8001/upload.php')
  //     .subscribe(res => {
  //       console.log(res);
  //       alert('Uploaded Successfully.');
  //     })
  // }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.dataService.getAuthors()
      .subscribe(data => {
        this.authorsList = data.map(ele => {
          return { label: ele.author_name, value: ele };
        });
      });

    this.dataService.getCategories()
      .subscribe(data => {
        this.categoriesList = data.map(ele => {
          return { label: ele.catg_name, value: ele };
        });
      });

     // this.dataService.getVariantItemsList(this.dataObj.prod_id).then((images) => (this.images = images));
     this.loadDropDownLists();

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public remove(index: number) {
    this.itemDataArray.splice(index, 1);
}

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultProductMaster);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
      this.loadDropDownLists();
      
    }
  }

  public editReviewData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultProductMaster);
    if (event == null) {
      this.isReviewAddDetails = true;
    } else {
      this.isReviewDetails = true;
	   this.bindDropDownList();
    }
  }

  private loadDropDownLists() {

    this.dataService.getTags()
    .subscribe(data => {
      this.tagList = data.map(ele => {
        return { label: ele.tag_name, value: ele.tag_id };
      });
    });

  }

  private bindDropDownList() {

    if (this.dataObj.author_id) {
      const item = this.authorsList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.author_id == this.dataObj.author_id);
      this.selectedAuthor = item ? item.value : undefined;
    }

    if (this.dataObj.catg_id) {
      const item = this.categoriesList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.catg_id == this.dataObj.catg_id);
      this.selectedCategory = item ? item.value : undefined;
    }

    if (this.dataObj.trending == 1) {
      this.trending = true;
    } else {
      this.trending = false;
    }
    console.log(this.trending);
    if (this.dataObj.popular == 1) {
      this.popular = true;
    } else {
      this.popular = false;
    }
    //console.log(this.dataObj.equipmentAvailabile);
    console.log(this.popular);

    this.dataService.getItemsList(this.dataObj.prod_id)
    .subscribe(dataList => {
      this.reviewitemDataArray = dataList.map((ele, indx) => {
        // tslint:disable-next-line: no-string-literal
        ele['s_no'] = indx + 1;
       // ele['mparams'] = else.mparams;
        
        return ele;
      });
    });

    this.dataService.getVariantItemsList(this.dataObj.prod_id)
    .subscribe(dataList => {
      this.itemDataArray = dataList.map((ele, indx) => {
        // tslint:disable-next-line: no-string-literal
        ele['s_no'] = indx + 1;
       // ele['mparams'] = else.mparams;
        
        return ele;
      });
    });

    const idList = this.dataObj.tag_id ? this.dataObj.tag_id.split(',') : [];
      console.log(idList);
      //console.log(this.tagList);
      const items = [];
      for (const val of idList) {
        // tslint:disable-next-line: triple-equals disable-next-line: no-string-literal
        const item = this.tagList.find(e => e['value'] == val);
        // tslint:disable-next-line: no-string-literal
        if (item) { items.push(item['value']); }
      }
      this.selectedTag = items;

      console.log(this.selectedTag);

  }


  public onAuthorChange(event) {
    this.dataObj.author_id = (event.value as Authors).author_id;
  }

  // public ontrendingChange(event) {
  //   this.dataObj.trending = (event.value as ProductMaster).trending;
  // }

  // public onpopularChange(event) {
  //   // print_r (event);
  //   this.dataObj.popular = (event.value as ProductMaster).popular;
  // }

  public onCategoryChange(event) {
    this.dataObj.catg_id = (event.value as Category).catg_id;
  }

  public onTagChange(event) {
    console.log(event);
    if (this.selectedTag) {
      console.log(this.selectedTag);
      const ids = [];
      this.selectedTag.forEach(e => {
        ids.push(e);
      });
      this.dataObj.tag_id = ids.join(',');
      console.log(this.dataObj.tag_id);
      //console.log(this.tagitemObj.tag_id.split(','));
    }
  }

  public onEquipmentChange(event) {
    console.log(event);
    //this.dataObj.equipmentName = event.value.equipmentName;
    this.dataObj.trending = event.value.trending;
  }
  public productTrendingchange(event) {
    if (this.trending == true) {
      this.dataObj.trending = 1;
    } else {
      this.dataObj.trending = 0;
    }

  }

  public productPopularchange(event) {
    if (this.popular == true) {
      this.dataObj.popular = 1;
    } else {
      this.dataObj.popular = 0;
    }

  }

  public fileUpload(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        //console.log(event.target.result);
      }
      const file = event.target.files[0];
      this.dataObj.prod_image = file;
      console.log(this.dataObj.prod_image);
    }
  }
  

  public viewImage(file: string) {
    return this.config.ipAddress + 'uploads/prod_image/' + file;
  }

  public viewVariantImage(file: string) {
    return this.config.ipAddress + 'uploads/variant_images/' + file;
  }

  public viewdocattachments(file: string) {
    return this.config.ipAddress + 'uploads/file_share_doc/' + file;
  }

  public viewPdf(file: string) {
    return this.config.ipAddress + 'uploads/file_share_doc/' + file;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public updateReviewData() {

      this.updateReviewCallType();
      console.log("hi u");
    
  }

  public createReviewDetails() {
    this.dataObj.reviewitems= this.reviewitemDataArray;
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isReviewAddDetails = false;
    this.isEditDetails = false;
    this.isReviewDetails = false;
    this.isReviewShow = false;
    this.url = undefined;
    this.selectedCategory = undefined;
    this.selectedTag = undefined;
  }

    public updateReviewCallType() {
    this.dataObj.reviewitems= this.reviewitemDataArray;
    this.dataService.updateReviewData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isReviewAddDetails = false;
    this.isEditDetails = false;
    this.isReviewDetails = false;
    this.selectedTag = undefined;
    this.url = undefined;
  }

  public createDetails() {
    this.dataObj.items = this.itemDataArray;
    console.log(this.dataObj.items);
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
    this.url = undefined;
    this.selectedCategory = undefined;
    this.selectedAuthor = undefined;
    this.selectedTag = undefined;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isReviewAddDetails = false;
    this.isEditDetails = false;
    this.isReviewDetails = false;
    this.url = undefined;
    this.itemObj.variant_code = '';
    this.itemObj.variant_name = '';
    this.visible = false; 
    this.attachviewvisible = false;
    this.documentattachvisible = false;
    this.selectedTag = undefined; 
    
  }

  public cancelattachEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isReviewAddDetails = false;
    this.isEditDetails = false;
    this.isReviewDetails = false;
    this.url = undefined;
    this.itemObj.variant_code = '';
    this.itemObj.variant_name = '';
    this.visible = false; 
    this.attachviewvisible = false; 
    this.documentattachvisible = false;
    
  }

  public updateCallType() {
    this.dataObj.items = this.itemDataArray;
    console.log(this.dataObj);
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
    this.selectedTag = undefined;
    this.url = undefined;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.prod_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: ProductMaster) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: ProductMaster) {
    this.dataObj = obj;
    this.isViewDetails = true;
    this.bindDropDownList();
  }

  public updateStatus(obj: ProductMaster) {
    this.dataService.updateStatus(obj);
  }


  public deleteItemRow(event) {
    this.itemDataArray.splice(
      this.itemDataArray.findIndex(e => e.s_no === event.s_no), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  public deleteReviewItemRow(event) {
    this.reviewitemDataArray.splice(
      this.reviewitemDataArray.findIndex(e => e.review_id === event.review_id), 1);
    this.reviewitemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }
  
  public addToreviewItemList() {
     
     const tmp = Object.assign({}, this.reviewitemObj);
     console.log(tmp);
     tmp.s_no = this.reviewitemDataArray.length + 1;
     const list = [... this.reviewitemDataArray];
     list.push(tmp);
     this.reviewitemDataArray = list;

     console.log(this.reviewitemDataArray);     
   
 }
 
  public addToItemList() {
   
      const tmp = Object.assign({}, this.itemObj);
      tmp.s_no = this.itemDataArray.length + 1;
      tmp.variant_attachment = this.uploadedFiles;
      //console.log(tmp);
      //console.log(this.uploadedFiles);
      tmp.variant_attachment_count = this.uploadedFiles.length;
      const list = [... this.itemDataArray];
      list.push(tmp);
      this.itemDataArray = list;
      console.log(this.itemDataArray);
      this.uploadedFiles = [];
      this.uploadedattchments = [];
     // this.selectedPack = undefined;
      this.itemObj = Object.assign({}, defaultVariants);
      console.log(this.itemObj);
  }

public showDialog(rowdata) {
    this.visible = true;  
    this.editattachmentsdata = rowdata;
    this.modelattachments =  rowdata.attachments;
    console.log(rowdata);
}

public showdocumentDialog(rowdata) {
  this.documentattachvisible = true;  
  this.editattachmentsdata = rowdata;
  this.modelattachments =  rowdata.attachments;
  console.log(rowdata);
}

public showAttachviewDialog(rowdata) {
  this.attachviewvisible = true;  
  this.editattachmentsdata = rowdata;
  this.modelattachments =  rowdata.attachments;
  console.log(rowdata);
}


public removeSelectedFile(modelattachments,image) {
  //console.log(modelattachments);

   let index = modelattachments.findIndex(e => e.vattach_id === image);
   if(index!== -1){
    modelattachments.splice(index,1);
   }
   console.log(image);
  
}

public removeSelecteddocFile(modelattachments,image) {
  //console.log(modelattachments);

   let index = modelattachments.findIndex(e => e.fileshare_id === image);
   if(index!== -1){
    modelattachments.splice(index,1);
   }
   console.log(image);
  
}

public oneditFileChange(event:any) {
  console.log(event);
  if (event.target.files && event.target.files) {
    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader();

      reader.onload = (event:any) => {
        console.log(event.target.result);
          this.edituploadedFiles.push(event.target.result); 

        //  this.myForm.patchValue({
        //     fileSource: this.images
        //  });
      }

      reader.readAsDataURL(event.target.files[i]);
    }
  }
  this.editattachmentsdata.variant_attachment = this.edituploadedFiles;
  console.log(this.editattachmentsdata);
}

public oneditdocFileChange(event:any) {
  console.log(event);
  if (event.target.files && event.target.files) {
    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader();

      reader.onload = (event:any) => {
        console.log(event.target.result);
          this.edituploadedFiles.push(event.target.result); 

        //  this.myForm.patchValue({
        //     fileSource: this.images
        //  });
      }

      reader.readAsDataURL(event.target.files[i]);
    }
  }
  this.editattachmentsdata.variant_attachment = this.edituploadedFiles;
  console.log(this.editattachmentsdata);
}

// public addToimgItemList() {
//     this.editattachmentsdata.variant_attachment = this.edituploadedFiles
//     console.log(this.editattachmentsdata);
//     //this.modelattachments =  rowdata;
// }


public updatevariantimages() {
  this.dataObj.items = this.editattachmentsdata;
  
  console.log(this.dataObj);

  this.dataService.updateAttachmentsData(this.dataObj).subscribe((data) => {
    this.dataService.getDataRecords();
  });

  this.isEditDetails = false;
  this.visible = false;  
}

public updatedocattachments() {
  this.dataObj.items = this.editattachmentsdata;
  
  console.log(this.dataObj);

  this.dataService.updatedocAttachmentsData(this.dataObj).subscribe((data) => {
    this.dataService.getDataRecords();
  });

  this.isEditDetails = false;
  this.documentattachvisible = false;  
}

}
