export interface Playlists {
    s_no?: number;
    playlists_id: number;
    playlist_id: number;
    user_id: number;
    prod_id: number;
    status: boolean;
    plist_name: string;
    user_fname: string;
    user_lname: string;
    prod_code: string;
    prod_desc: string;
    created_on: string;
}

export const defaultPlaylists = {
    playlists_id: 0,
    playlist_id: 0,
    user_id: 0,
    prod_id: 0,
    status: false,
    plist_name: '',
    user_fname: '',
    user_lname: '',
    prod_code: '',
    prod_desc: '',
    created_on: ''
};
