import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';
import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { TopsearchService } from 'src/app/service/common/topsearch.service';
import { Notification } from './topbar.model';

import {
  AuthenticationService,
  LeftMenuService
  // , MenuItem
} from 'src/app/service';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  public userName = '';
  public user_fname = '';
  public userEmail = '';
  public userRoleName = '';
  notificationItems: any[];
  headermessagesItems: any[];
  public data: any[];
  public notificationCount = 0;
  public MessagesCount = 0;
  public showNotifications = false;
  public hasNotifications = false;
  public searchkeyword = '';
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(private router: Router, private authService: AuthenticationService,private menuSvc: LeftMenuService, public dataService :TopsearchService) {}

  ngOnInit() {
    // get the notifications
   // this._fetchNotifications();
    this.openMobileMenu = false;
    this.userName = localStorage.getItem('userName');
    this.user_fname = localStorage.getItem('user_fname');
	this.userEmail = localStorage.getItem('userEmail');
	this.userRoleName = localStorage.getItem('userrolename');
  }

  /**
   * Change the language
   * @param language language
   */


  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  
  onSubmit(form: NgForm) {
    console.log('Your form data : ', this.searchkeyword);
    this.dataService.fetchData(this.searchkeyword)
  .subscribe(data => {
   // this.data = data;
    // set data in service which is to be shared
    this.dataService.setData(data)
  })
    this.router.navigate(['/search']);
  }

}
