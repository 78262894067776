export interface Department {
    s_no?: number;
    department_id: number;
    department_code: string;
    department_name: string;
    status: number;
}

export const defaultDepartment = {
    department_id: 0,
    department_code: '',
    department_name: '',
    status: 0
};
