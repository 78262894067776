export interface Blogs {
    s_no?: number;
    blog_id: number;
    short_desc: string;
    blog_title: string;
    custCountry: number;
    long_desc:string;
    custAddress: string;
    custtypeId: number;
    custtypeCode?: string;
    custcontactName?: string;
    custcontactNumber?: number;
    rec_status: boolean;
}

export const defaultBlogs: Blogs = {
    blog_id: 0,
    short_desc: '',
    blog_title: '',
    custCountry: 0,
    custAddress: '',
    long_desc:'',
    custtypeId: 0,
    custtypeCode: '',
    custcontactName: '',
    custcontactNumber: 0,
    rec_status: false
};
