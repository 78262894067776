export interface ProductMaster {
    s_no?: number;
	items: Array<Variants>;
    reviewitems: Array<Reviews>;
    prod_id: number;
    prod_code: string;
    prod_name: string;
    prod_desc: string;
    prod_sdesc: string;
    catg_id: number;
    catg_name: string;
    author_name: string;
    author_id: number;
    variant_id: number;
    rec_status: number;
    trending: number;
    popular: number;
    prod_image: File | string;
    status: number;
    tag_ids: string;
    tag_id: string;

}

export const defaultProductMaster = {
    prod_id: 0,
    prod_code: '',
    prod_name: '',
    prod_desc: '',
    prod_sdesc: '',
    catg_id: 0,
    author_id: 0,
    variant_id: 0,
    rec_status: 0,
    popular: 0,
    trending: 0,
    prod_image: '',
    catg_name: '',
    author_name: '',
    status: 0,
	items: [],
    reviewitems: [],
    tag_ids: '',
    tag_id: ''
};
export interface Variants {
    s_no?: number;
    variant_id:number;
    variant_code: string;
    variant_name: string;
    variant_attachment: Array<any>;
    fileshare_image: File | string;
    attachments: Array<any>;
    variant_attachment_count:number;
}

export const defaultVariants: Variants = {
    variant_id:0,
    variant_code: '',
    variant_name: '',
    variant_attachment: [],
    attachments: [],
    variant_attachment_count:0,
    fileshare_image: ''
};

export interface Reviews {
   // s_no?: number;
    review_id: number;
    rating: string;
    review: string;
    user_id: number;
    prod_id: number;
    variant_id: number;
    status: boolean;
    rec_status: boolean;
}

export const defaultReviews = {
    review_id: 0,
    rating: '',
    review: '',
    user_id: 0,
    prod_id: 0,
    variant_id: 0,
    status: false,
    rec_status: false
};
