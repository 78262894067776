export interface Filesharing {
    s_no?: number;
    fileshare_id: number;
    rating: string;
    fileshare: string;
    user_id: number;
    prod_id: number;
    variant_id: number;
    status: boolean;
    rec_status: boolean;
    type_id: number;
    variant_name: string;
    fileshare_image: Array<any>;
    catgname: string;
    catgprodname: string;
    attachments: Array<any>;
    
}

export const defaultFilesharing : Filesharing = {
    fileshare_id: 0,
    rating: '',
    fileshare: '',
    user_id: 0,
    prod_id: 0,
    variant_id: 0,
    status: false,
    rec_status: false,
    type_id: 0,
    variant_name: '',
    fileshare_image: [],
    catgname: '',
    catgprodname: '',
    attachments: [],

};

