export interface Postsetup {
    parent_comment: string;
    s_no?: number;
    pconfigId: number;
    prodId: number;
    prodCode?: string;
    prodName?: string;
    pharmacopiDesc?: string;
    prodSpecsDoc?: string;
    prodUom: number;
    batchSize: number;
    uomDesc?: string;
    stdBatchSize: number;
    stdBatchUom: number;
    stdBatchUomDesc?: string;
    items: Array<BCItem>;
    tagitems: Array<TagItem>;
    status: number;

    
    attach_id: number;
    prod_id: number;
    attach_name: File | string;
    attach_desc: string;
    attach_type: string;
    attach_type_id: number;
    attach_url: string;
    doc_type: string;

    post_attach: File | string;

    post_id: number;
    short_desc: string;
    long_desc: string;
    post_title: string;

    comment_id: number;
    user_id: number;
    comment_desc:string;
    country_id: number;
    parent_id: number;
    user_fname: string;

    bookmarkcnt: number;
    lcnt: number;
    ccnt: number;

    post_likes: string;

    tag_ids: string;
    tag_id: string;
    author_name: string;
    author_id: number;

}

export interface TagItem {
    s_no?: number;
    tag_ids: string;
    tag_id: string;
    tag_name: string;
    
    
}

export const defaultTagItem: TagItem = {

    tag_ids: '',
    tag_id: '',
    tag_name: ''
};

export interface BCItem {
    s_no?: number;
    pcstepId: number;
    pcstepName?: string;
    pcparamId: number;
    pcparamName?: string;
    pcparamMin: number;
    pcparamMax: number;
    pcparamUom: number;
    equipmentIds: string;
    pcparamUomDesc?: string;
    cycleTime: number;
    noOfPeople: number;
    stepCriticality_row?:string;
    stepCriticality: number;

    comment_id: number;
    user_id: number;
    comment_desc:string;
    country_id: number;
    parent_id: number;
    user_fname: string;
    author_id: number;
    author_name: string;
    
    
}

export const defaultBCItem: BCItem = {
    pcstepId: 0,
    pcparamId: 0,
    pcparamMin: 0,
    pcparamMax: 0,
    pcparamUom: 0,
    equipmentIds: '',
    cycleTime: 0,
    noOfPeople: 0,
    stepCriticality: 0,
    stepCriticality_row: '',

    comment_id: 0,
    user_id: 0,
    comment_desc:'',
    country_id: 0,
    parent_id: 0,
    user_fname: '',
    author_id: 0,
    author_name: '',
};


export const defaultPostsetup: Postsetup = {
    parent_comment: '',
    pconfigId: 0,
    prodId: 0,
    prodUom: 0,
    batchSize: 0,
    pharmacopiDesc: '',
    prodCode: '',
    uomDesc: '',
    stdBatchUom: 0,
    stdBatchUomDesc: '',
    stdBatchSize: 0,
    items: [],
    tagitems: [],
    status: 0,
    user_fname: '',

    attach_id: 0,
    prod_id: 0,
    attach_name: '',
    attach_desc: '',
    attach_type: '',
    attach_type_id: 0,
    attach_url: '',
    doc_type: '',

    post_attach: '',

    post_id: 0,
    short_desc: '',
    long_desc: '',
    post_title: '',

    comment_id: 0,
    user_id: 0,
    comment_desc:'',
    country_id: 0,
    parent_id: 0,

    bookmarkcnt: 0,
    lcnt: 0,
    ccnt: 0,

    tag_ids: '',
    tag_id: '',
    post_likes: '',
    author_name: '',
    author_id: 0,
};
