import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';
import { AdminMenuService } from 'src/app/service';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],

})

export class LeftsidebarComponent implements OnInit {

  @Input() sidebarType: string;
  
  menuItems = [];
  constructor(private router: Router, private authenticationService: AuthenticationService,private menuSvc: AdminMenuService) { }

  ngOnInit() {
    this.menuSvc.navmenus.subscribe(menuData => {
      this.menuItems =menuData;
    });
  }
  

  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: '/' } });
  }
}
