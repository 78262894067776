export interface ProductUOM {
    uomId: number;
    uomCode: string;
    uomDesc: string;
    status: boolean;
}

export const defaultProductUOM = {
    uomId: 0,
    uomCode: '',
    uomDesc: '',
    status: true
};
