import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiActions, UtilService, } from './util.service';
import { MsgService } from './msg.service';
import { Router } from '@angular/router';
import { ProductMaster,Country,State, ServiceActions, Variants, Authors, Category } from 'src/app/model';

@Injectable({
    providedIn: 'root'
})
export class MmdashboardService {
    public dataList = new Subject<Array<any>>();
    /** pieChart */

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgSvc: MsgService,
        private router: Router,
        private util: UtilService
    ) { }

    //public getmmdashboarddata(selecteddate) {

//   public getmmdashboarddata() {
//     const url = this.config.ipAddress + this.config.DashboardApi[ApiActions.GetSingle];
//     const data = new Subject<Array<ProductMaster>>();
//     const fData = new FormData();
//     this.http.post(url, null, { headers: undefined })
//       .subscribe((response: any) => {
//         if (response && response.result) {
//           const result = response.result.DashboardData;
//           data.next(result);
//         }
//       },
//         err => {
//             this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
//         });
//     return data;
//   }



  public getmmdashboarddata() {
    const result = new Subject<Array<{}>>();
    const url = this.config.ipAddress + this.config.DashboardApi[ApiActions.GetSingle];
    let dataArray = [];
    const fData = new FormData();
    //fData.append('calenderdate',this.util.getDate(selecteddate) );
    this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
        if (response && response.result) {
            dataArray = response.result.DashboardData;
            result.next(dataArray);
        }
    },
        err => {
            result.next([]);
              this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return result;
}

public getDashboardviewproducts() {
    const Dashboardproducts = new Subject<Array<any>>();
    this.http.post(this.config.ipAddress + this.config.DashboardApi[ApiActions.Get], null , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result.products) {
          const nCount = response.result.products;
          Dashboardproducts.next(nCount);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return Dashboardproducts;
  }

  public getDashboardbookmarkproducts() {
    const Dashboardproducts = new Subject<Array<any>>();
    this.http.post(this.config.ipAddress + this.config.DashboardApi[ApiActions.Create], null , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result.products) {
          const nCount = response.result.products;
          Dashboardproducts.next(nCount);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return Dashboardproducts;
  }

    public getmmdashboardProcurementdata(selecteddate) {
        const result = new Subject<Array<{ot:string;ot13:string;ot46:string;ot7:string;}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.Create];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray= response.procurementTime;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getmmdashboardSaledata(selecteddate) {
        const result = new Subject<Array<{}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.Update];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
               response.DispatchTime.map(e =>{
                dataArray= [{'name':'On Time','value':e.sot},{'name':'Delay by 1-3 days','value':e.sot13},{'name':'Delay by 4-6 days','value':e.sot46},{'name':'Delay more than 7 days','value':e.sot7}];

                });
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getmmdashboardPurchasereturns(selecteddate) {
        const result = new Subject<Array<{itmcatg:string;year_c:string;}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.GetTasks];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response.MonthwisePurchaseReturns;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getmonthreturncustdata(selecteddate) {
        const result = new Subject<Array<{cust_name:string;year_c:string;}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.EditTask];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response.dispatchsalesbycust;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getmonthreturnsuppdata(selecteddate) {
        const result = new Subject<Array<{suppname:string;year_c:string;}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.AddTask];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response.procurebysupplier;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getSalesreturnsdata(selecteddate) {
        const result = new Subject<Array<{prodphyfrm_desc:string;year_c:string;}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.ChangeTaskStatus];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response.DispatchTime;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getProcurementcategorydata(selecteddate) {
        const result = new Subject<Array<{}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.DeleteTask];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getOntimeDispatchcategorydata(selecteddate) {
        const result = new Subject<Array<{}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.GetFiless];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getmmdashboardItemcatpricetriends(selecteddate) {
        const result = new Subject<Array<{}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.DeleteFile];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response.ItemCategoryPriceTriends;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }
    public getSalespricetrendsdata(selecteddate) {
        const result = new Subject<Array<{}>>();
        const url = this.config.ipAddress + this.config.getMmdashboardApi[ApiActions.AddFiles];
        let dataArray = [];
        const fData = new FormData();
        fData.append('calenderdate',this.util.getDate(selecteddate) );
        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response && response) {
                dataArray=  response.DispatchTimebyCategory;
                result.next(dataArray);
            }
        },
            err => {
                result.next([]);
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;

    }

}
