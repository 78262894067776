import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { ProductAttachments,Country,State, ServiceActions, Variants, Authors, Category, ProductMaster } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ProductAttachmentsService implements ServiceActions {

  public pageName = 'ProductAttachments';
  public dataList = new Subject<Array<ProductAttachments>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.ProductAttachmentsApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<ProductAttachments> = [];
          response.result.Attachments.map(element => {
            element.status = element.rec_status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getProducts(): Subject<Array<ProductMaster>> {
    const url = this.config.ipAddress + this.config.ProductMasterApi[ApiActions.Get];
    const dataArray: Array<ProductMaster> = [];
    const data = new Subject<Array<ProductMaster>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Products.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public addData(objData: ProductAttachments) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: ProductAttachments) {
    const fData = new FormData();
    fData.append('attach_id', objData.attach_id + '');
    fData.append('attach_type_id', objData.attach_type_id + '');
    fData.append('attach_desc', objData.attach_desc);
    fData.append('attach_name', objData.attach_name);
    fData.append('rec_status', objData.rec_status ? '1' : '0');

    return fData;
  }

  public updateData(objData: ProductAttachments) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('prod_code', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: ProductAttachments) {
    const fData = new FormData();
    fData.append('rec_status', objData.rec_status ? '1' : '0');
    fData.append('prod_id', objData.prod_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

 

}
