export interface Bookmarks {
    s_no?: number;
    prod_id: number;
    bookmark_id: number;
    blog_id: number;
    post_id: number;
    user_id: number;
    status: boolean;
}

export const defaultBookmarks = {
    prod_id: 0,
    bookmark_id: 0,
    blog_id: 0,
    post_id: 0,
    user_id: 0,
    status: false
};
