import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MasterActions, GridHeader, Banner, defaultBanner, GridHeaderEx, ProductMaster, Variants, User } from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, BannerService, UtilService, UserType, AppConfigService } from 'src/app/service';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy, MasterActions {
  @ViewChild('banner_image', { static: true }) specDoc: ElementRef;
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Banner>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Banner = defaultBanner;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public productsList: Array<SelectItem> = [];
  public selectedtype: any;

  public variantList: Array<SelectItem> = [];
  public selectedVariant: Banner;

  public userList: Array<SelectItem> = [];
  public selectedUser: User;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public url : string | ArrayBuffer;

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'banner_id', header: 'Id', isVisible: false, isDefault: true },
    { field: 'catgname', header: 'Type', isVisible: true, isDefault: true },
    { field: 'catgprodname', header: 'Selected Type', isVisible: true, isDefault: true },
    { field: 'banner_image', header: 'Attachment', isVisible: true, isDefault: true },
    // { field: 'prod_desc', header: 'Product Name', isVisible: true, isDefault: true },
    // { field: 'variant_name', header: 'Variant Name', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  selecttype = [
    { label: "Select Type", value: 0 },
    { label: "Category", value: 1 },
    { label: "Product", value: 2 }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private config: AppConfigService,
    private dataService: BannerService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.dataService.getProducts()
      .subscribe(data => {
        this.productsList = data.map(ele => {
          return { label: ele.prod_desc, value: ele };
        });
      });

    // this.dataService.getVariants()
    // .subscribe(data => {
    //   this.variantList = data.map(ele => {
    //     return { label: ele.variant_name, value: ele };
    //   });
    // });

    this.dataService.getUsers()
      .subscribe(data => {
        this.userList = data.map(ele => {
          return { label: ele.user_fname, value: ele };
        });
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultBanner);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {

    if (this.dataObj.type_id) {

      console.log(this.dataObj.type_id);  
      //console.log(this.selecttype);
      const item = this.selecttype
      // tslint:disable-next-line: triple-equals
      .find(e => e.value == this.dataObj.type_id);
      this.selectedtype = item ? item.value : undefined;
      //console.log(this.selectedtype);
      this.dataService.getVariants(this.dataObj.type_id)
      .subscribe(data => {
        this.variantList = data.map(ele => {
          return { label: ele.variant_name, value: ele };
        });
        const item = this.variantList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.variant_id == this.dataObj.variant_id);
        this.selectedVariant = item ? item.value : undefined;
        console.log(this.selectedVariant);
      });

    }

    if (this.dataObj.user_id) {
      const item = this.userList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.user_id == this.dataObj.user_id);
      this.selectedUser = item ? item.value : undefined;
    }

  }

  public onVariantChange(event) {
    console.log(event);
    this.dataObj.variant_id = (event.value as Variants).variant_id;
  } 

  public onProductChange(event) {
    //console.log(event.value.value);
    //console.log(event);
    //console.log(event.value);
    this.dataObj.type_id = event.value;
    //console.log(this.dataObj.type_id);
    this.dataService.getVariants(event.value)
      .subscribe(data => {
        this.variantList = data.map(ele => {
          return { label: ele.variant_name, value: ele };
        });
      });
  }

  public onUserChange(event) {
    this.dataObj.user_id = (event.value as User).user_id;
  }

  public fileUpload(event) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log(event.target.result);
      }
      const file = event.target.files[0];
      this.dataObj.banner_image = file;
    }
  }

  public viewImage(file: string) {
    return this.config.ipAddress + 'uploads/banner_image/' + file;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
    this.resetFormData();
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.url = undefined;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
    this.resetFormData();
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.banner_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Banner) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Banner) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: Banner) {
    this.dataService.updateStatus(obj);
  }
}
