export interface ProcessParams {
    s_no?: number;
    pcparamId: number;
    pcparamName: string;
    status: boolean;
}

export const defaultProcessParams: ProcessParams = {
    pcparamId: 0,
    pcparamName: '',
    status: true
};
