export interface Blogsetup {
    parent_comment: string;
    s_no?: number;
    pconfigId: number;
    prodId: number;
    prodCode?: string;
    prodName?: string;
    pharmacopiDesc?: string;
    prodSpecsDoc?: string;
    prodUom: number;
    batchSize: number;
    uomDesc?: string;
    stdBatchSize: number;
    stdBatchUom: number;
    stdBatchUomDesc?: string;
    items: Array<PCItem>;
    blogtagitems: Array<BlogTagItem>;
    status: number;

    author_id: number;
    author_name: string;

    
    attach_id: number;
    prod_id: number;
    attach_name: File | string;
    attach_desc: string;
    attach_type: string;
    attach_type_id: number;
    attach_url: string;
    doc_type: string;

    blog_attach: File | string;

    blog_id: number;
    short_desc: string;
    long_desc: string;
    blog_title: string;

    comment_id: number;
    user_id: number;
    comment_desc:string;
    country_id: number;
    parent_id: number;
    user_fname: string;

    bookmarkcnt: number;
    lcnt: number;
    ccnt: number;
    blog_likes: string;

    tag_ids: string;
    tag_id: string;

}

export interface PCItem {
    s_no?: number;
    pcstepId: number;
    pcstepName?: string;
    pcparamId: number;
    pcparamName?: string;
    pcparamMin: number;
    pcparamMax: number;
    pcparamUom: number;
    equipmentIds: string;
    pcparamUomDesc?: string;
    cycleTime: number;
    noOfPeople: number;
    stepCriticality_row?:string;
    stepCriticality: number;
    mparams:Array<Mprocess>;

    comment_id: number;
    user_id: number;
    comment_desc:string;
    country_id: number;
    parent_id: number;
    user_fname: string;

    author_id: number;
    author_name: string;
    
}

export const defaultPCItem: PCItem = {
    pcstepId: 0,
    pcparamId: 0,
    pcparamMin: 0,
    pcparamMax: 0,
    pcparamUom: 0,
    equipmentIds: '',
    cycleTime: 0,
    noOfPeople: 0,
    stepCriticality: 0,
    stepCriticality_row: '',
    mparams:[],

    comment_id: 0,
    user_id: 0,
    comment_desc:'',
    country_id: 0,
    parent_id: 0,
    user_fname: '',
    author_id: 0,
    author_name: '',
};
export interface Mprocess {
    s_no?: number;
    multiparam_id : number;
    proconfig_id: number;
    pcstep_id: number;
    pcparam_id 	: number;
    pcparam_min	: number;
    pcparam_max	 : number;	
    pcparam_uom	 : number;	
    pcparamName_uom:string;
    pcparamName:string;
}

export const defaultMprocess: Mprocess = {
    multiparam_id : 0,
    proconfig_id: 0,
    pcstep_id: 0,
    pcparam_id 	: 0,
    pcparam_min	: 0,
    pcparam_max	 : 0,	
    pcparam_uom	 : 0,
    pcparamName_uom: '',
    pcparamName:'',	
};

export const defaultBlogsetup: Blogsetup = {
    parent_comment: '',
    pconfigId: 0,
    prodId: 0,
    prodUom: 0,
    batchSize: 0,
    pharmacopiDesc: '',
    prodCode: '',
    uomDesc: '',
    stdBatchUom: 0,
    stdBatchUomDesc: '',
    stdBatchSize: 0,
    items: [],
    blogtagitems: [],
    status: 0,
    user_fname: '',

    attach_id: 0,
    prod_id: 0,
    attach_name: '',
    attach_desc: '',
    attach_type: '',
    attach_type_id: 0,
    attach_url: '',
    doc_type: '',

    blog_attach:'',

    blog_id: 0,
    short_desc: '',
    long_desc: '',
    blog_title: '',

    comment_id: 0,
    user_id: 0,
    comment_desc:'',
    country_id: 0,
    parent_id: 0,

    bookmarkcnt: 0,
    lcnt: 0,
    ccnt: 0,
    blog_likes: '',

    tag_ids: '',
    tag_id: '',

    author_id: 0,
    author_name: '',
};

export interface BlogTagItem {
    s_no?: number;
    tag_ids: string;
    tag_id: string;
    
    
}

export const defaultBlogTagItem: BlogTagItem = {

    tag_ids: '',
    tag_id: ''
};
