import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import {
  GridHeaderEx,
  GridHeader,
  Postsetup,
  defaultPostsetup,
  Mprocess,
  defaultMprocess,
  PostComments,
  defaultTagItem,
  TagItem,
  User,
  Tags,
  Authors
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  PostsetupService,
  AppConfigService
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-postsetup',
  templateUrl: './postsetup.component.html',
  styleUrls: ['./postsetup.component.scss']
})
export class PostsetupComponent implements OnInit, OnDestroy {
  @ViewChild('post_attach', { static: true }) postattach: ElementRef;
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<any>;
  public itemDataArray: Array<any> = [];
  public itemmparams: Array<any> = [];
  public itemTagDataArray: Array<any> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Postsetup = defaultPostsetup;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public isBookmarkDetails = false;

  public url : string | ArrayBuffer;

  public itemObj: Postsetup = defaultPostsetup;

  public itemparamsObj: Mprocess = defaultMprocess;

  public postcommentsList: Array<SelectItem> = [];
  public selectedpostComment = 0;

  public tagitemObj: TagItem = defaultTagItem;
  public tagList: Array<SelectItem> = [];
  public selectedTag: Array<Tags> = [];

  public userList: Array<SelectItem> = [];
  public selectedUser: User;

  public authorsList: Array<SelectItem> = [];
  public selectedAuthor: Authors;

  clonedMproces: { [s: string]: Mprocess; } = {};
  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : this.isBookmarkDetails ? 'Bookmarks ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'post_title', header: 'post Title', isVisible: true, isDefault: true },
    { field: 'short_desc', header: 'post Short Descriprtion', isVisible: true, isDefault: true },
    { field: 'long_desc', header: 'Long Description', isVisible: true, isDefault: true },
    { field: 'post_attach', header: 'Attachment', isVisible: true, isDefault: true },
    { field: 'doc_type', header: 'Document Type', isVisible: false, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public itemCols = [
    { field: 's_no', header: 'S No' },
    { field: 'parent_comment', header: 'Parent Comment' },
    { field: 'comment_desc', header: 'Comment Description' },
    { field: 'author_name', header: 'Author' }
  ];

  public itemTagCols = [
    { field: 's_no', header: 'S No' },
    { field: 'tag_id', header: 'Tags' }
    
  ];

  public bookmarkCols = [
    { field: 's_no', header: 'S No' },
    // { field: 'parent_comment', header: 'Parent Comment' },
    { field: 'user_fname', header: 'User First Name' },
    { field: 'user_lname', header: 'User Last Name' }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: PostsetupService,
    public util: UtilService,
    private config: AppConfigService
  ) {
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.loadDropDownLists();



    this.dataService.getUsers()
    .subscribe(data => {
      this.userList = data.map(ele => {
        return { label: ele.user_fname, value: ele };
      });
    });

    this.dataService.getauthors()
    .subscribe(data => {
      this.authorsList = data.map(ele => {
        return { label: ele.author_name, value: ele };
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public fileUpload(event) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log(event.target.result);
      }

		const file = event.target.files[0];
    console.log(file);
		this.dataObj.post_attach = file;
	}
}

  public viewImage(file: string) {
    return this.config.ipAddress + 'uploads/post_attach/' + file;
  }

  public onpostCommentChange(event) {
    this.itemObj.parent_id = event.value.comment_id;
    this.itemObj.parent_comment = (event.value as PostComments).comment_desc;
  }

  public onUserChange(event) {
    this.itemObj.user_id = (event.value as User).user_id;
    this.itemObj.user_fname = (event.value as User).user_fname;
  }

  public onAuthorChange(event) {
    this.itemObj.author_id = (event.value as Authors).author_id;
    this.itemObj.author_name = (event.value as Authors).author_name;
  }

  public onTagChange(event) {
    console.log(event);
    if (this.selectedTag) {
      console.log(this.selectedTag);
      const ids = [];
      this.selectedTag.forEach(e => {
        ids.push(e);
      });
      this.dataObj.tag_id = ids.join(',');
      console.log(this.dataObj.tag_id);
      //console.log(this.tagitemObj.tag_id.split(','));
    }
  }

  private loadDropDownLists() {

    this.dataService.getTags()
      .subscribe(data => {
        this.tagList = data.map(ele => {
          return { label: ele.tag_name, value: ele.tag_id };
        });
      });

    this.dataService.getPostComments(this.dataObj.post_id)
      .subscribe(data => {
        this.postcommentsList = data.map(ele => {
          return { label: ele.comment_desc, value: ele };
        });
    });

 
  }

  public addToItemList() {
     
    console.log(this.itemObj);
    this.itemObj.post_id = this.dataObj.post_id;
    this.dataService.updatecommentData(this.itemObj).subscribe((data) => {
     // this.bindDropDownList();
      this.loadDropDownLists();
    });

    //  this.itemObj.parent_id = this.selectedpostComment;
    //  //this.itemObj.user_fname = this.selectedUser.user_fname;
    //  this.itemObj.author_name = this.selectedAuthor.author_name;
    const tmp = Object.assign({}, this.itemObj);

    tmp.s_no = this.itemDataArray.length + 1;
    const list = [... this.itemDataArray];
    list.push(tmp);
    this.itemDataArray = list;

    console.log(this.itemDataArray);
    this.itemObj.comment_desc = '';
    this.selectedpostComment = 0;
    this.selectedAuthor = undefined;
    
  }

  public addToTagItemList() {
     
    // this.itemObj.parent_id = this.selectedpostComment;
    // this.itemObj.user_fname = this.selectedUser.user_fname;
  
     const tmp = Object.assign({}, this.tagitemObj);

     tmp.s_no = this.itemTagDataArray.length + 1;
     const list = [... this.itemTagDataArray];
     list.push(tmp);
     this.itemTagDataArray = list;

     console.log(this.itemTagDataArray);
     this.selectedTag = [];
     
   
 }

  public deleteItemRow(event) {
    console.log(event);
    this.itemObj.comment_id = event.comment_id;
    console.log(this.itemObj.comment_id);
    this.dataService.deleteData(this.itemObj).subscribe((data) => {
    // this.bindDropDownList();
      this.loadDropDownLists();
    });
    this.itemDataArray.splice(
      this.itemDataArray.findIndex(e => e.pcstepId === event.pcstepId), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  public deleteTagItemRow(event) {
    this.itemTagDataArray.splice(
      this.itemTagDataArray.findIndex(e => e.pcstepId === event.tag_id), 1);
    this.itemTagDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  public updateData() {
    this.dataObj.items = this.itemDataArray;
    this.dataObj.tagitems = this.itemTagDataArray;
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public updateStatus(obj: Postsetup) {
    this.dataService.updateStatus(obj);
  }

  public viewData(obj: Postsetup) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
    
  }

  public viewBookmarkData(obj: Postsetup) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isBookmarkDetails = true;
    
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultPostsetup);

    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
      this.loadDropDownLists();
    }
  }

  private bindDropDownList() {
   
    this.dataService.getItemsList(this.dataObj.post_id)
    .subscribe(dataList => {
      this.itemDataArray = dataList.map((ele, indx) => {
        // tslint:disable-next-line: no-string-literal
        ele['s_no'] = indx + 1;
       // ele['mparams'] = else.mparams;
        
        return ele;
      });
    });

    this.dataService.getPostComments(this.dataObj.post_id)
    .subscribe(data => {
      this.postcommentsList = data.map(ele => {
        return { label: ele.comment_desc, value: ele };
      });
    });

    this.dataService.getBookmarksList(this.dataObj.post_id)
    .subscribe(dataList => {
      this.itemmparams = dataList.map((ele, indx) => {
        // tslint:disable-next-line: no-string-literal
        ele['s_no'] = indx + 1;
       // ele['mparams'] = else.mparams;
        
        return ele;
      });
    });

    const idList = this.dataObj.tag_id ? this.dataObj.tag_id.split(',') : [];
     console.log(idList);
    //console.log(this.tagList);
    const items = [];
    for (const val of idList) {
      // tslint:disable-next-line: triple-equals disable-next-line: no-string-literal
      const item = this.tagList.find(e => e['value'] == val);
      // tslint:disable-next-line: no-string-literal
      if (item) { items.push(item['value']); }
    }
    this.selectedTag = items;

    console.log(this.selectedTag);
    
     
  }

  public deleteData(obj: Postsetup) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.post_id === 0 ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    this.dataObj.items = this.itemDataArray;
    this.dataObj.tagitems = this.itemTagDataArray;
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isBookmarkDetails = false;
    this.isEditShow = false;
    this.itemDataArray = [];
    this.url = undefined;
    this.dataService.getDataRecords();
    // TODO: reset all the selected items.
    this.dataObj = Object.assign({}, defaultPostsetup);
  }

  public viewPdf(file: string) {
    return this.config.ipAddress + '/pharma/uploads/specs_doc/' + file;
  }

}
