import { Component, OnInit, OnDestroy } from '@angular/core';
import { MasterActions, GridHeader, Review, defaultReview, GridHeaderEx, ProductMaster, Variants, User } from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, ReviewService,UtilService,UserType } from 'src/app/service';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Review>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Review = defaultReview;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public productsList: Array<SelectItem> = [];
  public selectedProduct: ProductMaster;

  public variantList: Array<SelectItem> = [];
  public selectedVariant: Variants;

  public userList: Array<SelectItem> = [];
  public selectedUser: User;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'review_id', header: 'Id', isVisible: false, isDefault: true },
    { field: 'rating', header: 'Rating', isVisible: true, isDefault: true },
    { field: 'review', header: 'Review', isVisible: true, isDefault: true },
    { field: 'user_fname', header: 'User Name', isVisible: true, isDefault: true },
    { field: 'prod_desc', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'variant_name', header: 'Variant Name', isVisible: true, isDefault: true },
     { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private dataService: ReviewService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

      this.dataService.getProducts()
      .subscribe(data => {
        this.productsList = data.map(ele => {
          return { label: ele.prod_desc, value: ele };
        });
      });

      // this.dataService.getVariants()
      // .subscribe(data => {
      //   this.variantList = data.map(ele => {
      //     return { label: ele.variant_name, value: ele };
      //   });
      // });

      this.dataService.getUsers()
      .subscribe(data => {
        this.userList = data.map(ele => {
          return { label: ele.user_fname, value: ele };
        });
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultReview);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
    }
  }

  private bindDropDownList() {

    if (this.dataObj.prod_id) {
      const item = this.productsList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.prod_id == this.dataObj.prod_id);
      this.selectedProduct = item ? item.value : undefined;
    }

    if (this.dataObj.variant_id) {
      const item = this.variantList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.variant_id == this.dataObj.variant_id);
      this.selectedVariant = item ? item.value : undefined;
    }

    if (this.dataObj.user_id) {
      const item = this.userList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.user_id == this.dataObj.user_id);
      this.selectedUser = item ? item.value : undefined;
    }
    
  }

  public onVariantChange(event) {
    this.dataObj.variant_id = (event.value as Variants).variant_id;
  }

  public onProductChange(event) {
    this.dataObj.prod_id = (event.value as ProductMaster).prod_id;
     this.dataService.getVariants(event.value.prod_id)
      .subscribe(data => {
        this.variantList = data.map(ele => {
          return { label: ele.variant_name, value: ele };
        });
      });
  }

  public onUserChange(event) {
    this.dataObj.user_id = (event.value as User).user_id;
  }
 
  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.review_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Review) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Review) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: Review) {
    this.dataService.updateStatus(obj);
  }
}
