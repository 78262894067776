export interface Country {
    s_no?: number;
    country_id: number;
    country_code: string;
    country_name: string;
    status: boolean;
}

export const defaultCountry = {
    country_id: 0,
    country_code: '',
    country_name: '',
    status: false
};
