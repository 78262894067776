import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { Category,Country,State, ServiceActions } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CategoryService implements ServiceActions {

  public pageName = 'Category';
  public dataList = new Subject<Array<Category>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.CategoryApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Category> = [];
          response.result.categories.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getcategories(): Subject<Array<Category>> {
    const url = this.config.ipAddress + this.config.CategoryApi[ApiActions.Get];
    const dataArray: Array<Category> = [];
    const data = new Subject<Array<Category>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.categories.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  // public getCountrys(): Subject<Array<Country>> {
  //   const url = this.config.ipAddress + this.config.CountryApi[ApiActions.Get];
  //   const dataArray: Array<Country> = [];
  //   const data = new Subject<Array<Country>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.Country.map(element => {
  //           element.status = Number(element.status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }
  // public getStates(): Subject<Array<State>> {
  //   const url = this.config.ipAddress + this.config.StateApi[ApiActions.Get];
  //   const dataArray: Array<State> = [];
  //   const data = new Subject<Array<State>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.State.map(element => {
  //           element.status = Number(element.status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }

  public addData(objData: Category) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: Category) {
    const fData = new FormData();
    fData.append('catg_name', objData.catg_name + '');
    fData.append('catg_id', objData.catg_id + '');
    fData.append('parent_id', objData.parent_id + '');
    // fData.append('CategoryCode', objData.Category_code);
    fData.append('catg_images', objData.catg_images);
    fData.append('status', objData.status ? '1' : '0');

    return fData;
  }

  public updateData(objData: Category) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('catg_id', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Category) {
    const fData = new FormData();
    fData.append('status', objData.status ? '1' : '0');
    fData.append('catg_id', objData.catg_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

}
