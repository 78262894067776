export interface Docsharerequest {
    s_no?: number;
    d_id: number;
    user_id: number;
    variant_id: number;
    prod_id: number;
    status: boolean;
    enq_qty: string;
    e_email_id: string;
    e_name: string;
    e_phone: string;
    e_message: string;
    prod_name: string;
    created_on: string;
    variant_name: string;
    user_fname: string;
    user_lname: string;
}

export const defaultDocsharerequest = {
    d_id: 0,
    user_id: 0,
    prod_id: 0,
    variant_id: 0,
    status: false,
    enq_qty: '',
    e_email_id: '',
    e_name: '',
    e_phone: '',
    e_message: '',
    created_on: '',
    prod_name: '',
    variant_name: '',
    user_fname: '',
    user_lname: ''
};
