import { Injectable } from '@angular/core';
import { ApiActions } from './util.service';
import { Subject } from 'rxjs';
import { BlogComments, Country, User, Blogs } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { CommonDataService } from './common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class BlogCommentsService {
  public pageName = 'BlogComments';
  public dataList = new Subject<Array<BlogComments>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.BlogCommentsApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<BlogComments> = [];
          response.result.BlogComments.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getBlogComments(): Subject<Array<BlogComments>> {
    const url = this.config.ipAddress + this.config.BlogCommentsApi[ApiActions.Get];
    const dataArray: Array<BlogComments> = [];
    const data = new Subject<Array<BlogComments>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.BlogComments.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getUsers(): Subject<Array<User>> {
    const url = this.config.ipAddress + this.config.userApi[ApiActions.Get];
    const dataArray: Array<User> = [];
    const data = new Subject<Array<User>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.userslist.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getBlogs(): Subject<Array<Blogs>> {
    const url = this.config.ipAddress + this.config.BlogsApi[ApiActions.Get];
    const dataArray: Array<Blogs> = [];
    const data = new Subject<Array<Blogs>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Blogs.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getCountrys(): Subject<Array<Country>> {
    const url = this.config.ipAddress + this.config.PostsApi[ApiActions.Get];
    const dataArray: Array<Country> = [];
    const data = new Subject<Array<Country>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Country.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public addData(objData: BlogComments) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: BlogComments) {
    const fData = new FormData();
    fData.append('comment_id', objData.comment_id + '');
    fData.append('comment_desc', objData.comment_desc);
    fData.append('parent_id', objData.parent_id + '');
    fData.append('blog_id', objData.blog_id + '');
    fData.append('user_id', objData.user_id + '');
    fData.append('status', objData.status ? '1' : '0');
    return fData;
  }

  public updateData(objData: BlogComments) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('comments_id', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: BlogComments) {
    const fData = new FormData();
    fData.append('BlogComments', objData.status ? '1' : '0');
    fData.append('comments_id', objData.comment_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

}
