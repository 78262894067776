import { Injectable } from '@angular/core';
import { ApiActions } from './util.service';
import { Subject } from 'rxjs';
import { Testimonials } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { MsgService } from './msg.service';
import { CommonDataService } from './common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TestimonialsService {

  public pageName = 'Testimonials';
  public dataList = new Subject<Array<Testimonials>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.TestimonialsApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Testimonials> = [];
          response.result.Testimonials.map(element => {
            element.status = element.status + '' === '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  } 

  public addData(objData: Testimonials) {
    const fData = new FormData();
    fData.append('testmoni_id', objData.testmoni_id + '');
    fData.append('testmoni_name', objData.testmoni_name);
    fData.append('tprofile_image', objData.tprofile_image);
    fData.append('testmoni_msg', objData.testmoni_msg);
    fData.append('testmoni_rating', objData.testmoni_rating);
    fData.append('status', objData.status ? '1' : '0');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  public updateData(objData: Testimonials) {
    const fData = new FormData();
    fData.append('testmoni_id', objData.testmoni_id + '');
    fData.append('testmoni_name', objData.testmoni_name);
    fData.append('tprofile_image', objData.tprofile_image);
    fData.append('testmoni_msg', objData.testmoni_msg);
    fData.append('testmoni_rating', objData.testmoni_rating);
    fData.append('status', objData.status ? '1' : '0');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('testmoni_id', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData:Testimonials) {
    const fData = new FormData();
    fData.append('status', objData.status ? '1' : '0');
    fData.append('testmoni_id', objData. testmoni_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }
}
