import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { Tags,Country, ServiceActions } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TagsService implements ServiceActions {

  public pageName = 'Tags';
  public dataList = new Subject<Array<Tags>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }
  

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.TagsApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Tags> = [];
          response.result.Tags.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }
  public gettags(): Subject<Array<Tags>> {
    const url = this.config.ipAddress + this.config.TagsApi[ApiActions.Get];
    const dataArray: Array<Tags> = [];
    const data = new Subject<Array<Tags>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Tags.map(element => {
            element.status = Number(element.status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  // public getCountrys(): Subject<Array<Country>> {
  //   const url = this.config.ipAddress + this.config.CountryApi[ApiActions.Get];
  //   const dataArray: Array<Country> = [];
  //   const data = new Subject<Array<Country>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.Country.map(element => {
  //           element.status = Number(element.status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }
  // public getStates(): Subject<Array<State>> {
  //   const url = this.config.ipAddress + this.config.StateApi[ApiActions.Get];
  //   const dataArray: Array<State> = [];
  //   const data = new Subject<Array<State>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.State.map(element => {
  //           element.status = Number(element.status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }

  public addData(objData: Tags) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: Tags) {
    const fData = new FormData();
    fData.append('tag_id', objData.tag_id + '');
    fData.append('tag_name', objData.tag_name + '');
    fData.append('parent_id', objData.parent_id + '');
    fData.append('status', objData.status ? '1' : '0');

    return fData;
  }

  public updateData(objData: Tags) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('tag_id', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Tags) {
    const fData = new FormData();
    fData.append('status', objData.status ? '1' : '0');
    fData.append('tag_id', objData.tag_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

}
