import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  GridHeader,
  City,
  Country,
  State,
  defaultCity,
  GridHeaderEx 
} from 'src/app/model';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import {
  MsgService
  , CityService
  , UtilService
  , UserType
} from 'src/app/service';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<City>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: City = defaultCity;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public countryList: Array<SelectItem> = [];
  public selectedCountry: Country;

  public stateList: Array<SelectItem> = [];
  public selectedState: Country;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isAddShow() {
    return this.isAddDetails;
  }

  public set isAddShow(value: boolean) {
    this.isAddDetails = value;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	{ field: 'city_id', header: 'Id', isVisible: false, isDefault: true },
    // { field: 'country_code', header: 'Country', isVisible: true, isDefault: true },
    // { field: 'state_code', header: 'State', isVisible: true, isDefault: true },
    // { field: 'city_code', header: 'City Code', isVisible: true, isDefault: true },
    { field: 'city_name', header: 'City Name', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private dataService: CityService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
      // this.dataService.getCountrys()
      // .subscribe(data => {
      //   this.countryList = data.map(ele => {
      //     return { label: ele.country_name, value: ele };
      //   });
      // });

      // this.dataService.getStates()
      // .subscribe(data => {
      //   this.stateList = data.map(ele => {
      //     return { label: ele.state_desc, value: ele };
      //   });
      // });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultCity);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
	   this.bindDropDownList();
    }
  }
  private bindDropDownList() {

    if (this.dataObj.country_id) {
      const item = this.countryList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.country_id == this.dataObj.country_id);
      this.selectedCountry = item ? item.value : undefined;
    }
    if (this.dataObj.state_id) {
      const item = this.stateList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.state_id == this.dataObj.state_id);
      this.selectedState = item ? item.value : undefined;
    }
  }
  public onCountryChange(event) {
    this.dataObj.country_id = (event.value as Country).country_id;
  }
  public onStateChange(event) {
    this.dataObj.state_id = (event.value as State).state_id;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.city_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: City) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: City) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: City) {
    console.log(obj);
    this.dataService.updateStatus(obj);
  }
}
