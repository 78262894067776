export interface Posts {
    s_no?: number;
    post_id: number;
    short_desc: string;
    post_title: string;
    custCountry: number;
    long_desc:string;
    custAddress: string;
    custtypeId: number;
    custtypeCode?: string;
    custcontactName?: string;
    custcontactNumber?: number;
    rec_status: boolean;
}

export const defaultPosts: Posts = {
    post_id: 0,
    short_desc: '',
    post_title: '',
    custCountry: 0,
    custAddress: '',
    long_desc:'',
    custtypeId: 0,
    custtypeCode: '',
    custcontactName: '',
    custcontactNumber: 0,
    rec_status: false
};
