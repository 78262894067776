export interface Banner {
    s_no?: number;
    banner_id: number;
    rating: string;
    banner: string;
    user_id: number;
    prod_id: number;
    variant_id: number;
    status: boolean;
    rec_status: boolean;
    type_id: number;
    variant_name: string;
    banner_image: File | string;
    catgname: string;
    catgprodname: string;
    
}

export const defaultBanner = {
    banner_id: 0,
    rating: '',
    banner: '',
    user_id: 0,
    prod_id: 0,
    variant_id: 0,
    status: false,
    rec_status: false,
    type_id: 0,
    variant_name: '',
    banner_image: '',
    catgname: '',
    catgprodname: ''

};
