export interface Uom {
    s_no?: number;
    uom_id: number;
    uom_code: string;
    uom_desc: string;
    status: number;
}

export const defaultUom = {
    uom_id: 0,
    uom_code: '',
    uom_desc: '',
    status: 0
};
