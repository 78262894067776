export interface Category {
    s_no?: number;
    city_id: number;
    country_id: number;
    state_id: number;
    city_code: string;
    city_desc: string;
    city_name: string;

    catg_id: number;
    catg_name: string;
    parent_id: number;
    status: number;
    rec_status: number;
    catg_images: File | string;
    parent_name: string;
}

export const defaultCategory = {
    city_id: 0,
    country_id: 0,
    state_id: 0,
    city_code: '',
    city_desc: '',
    city_name: '',
    catg_id: 0,
    catg_name: '',
    parent_id: 0,
    catg_images: '',
    status: 0,
    rec_status: 0,
    parent_name: ''
};
