import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MasterActions, GridHeader, Filesharing, defaultFilesharing, GridHeaderEx, ProductMaster, Variants, User ,defaultVariants} from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, FilesharingService, UtilService, UserType, AppConfigService } from 'src/app/service';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-filesharing',
  templateUrl: './filesharing.component.html',
  styleUrls: ['./filesharing.component.scss']
})
export class FilesharingComponent implements OnInit, OnDestroy, MasterActions {
  @ViewChild('fileshare_image', { static: true }) specDoc: ElementRef;
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Filesharing>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Filesharing = defaultFilesharing;
  public itemObj: Variants = Object.assign({}, defaultVariants);
  public itemDataArray:Array<any> = [];
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};

  public productsList: Array<SelectItem> = [];
  public selectedtype: any;

  public variantList: Array<SelectItem> = [];
  public selectedVariant: Filesharing;

  public userList: Array<SelectItem> = [];
  public selectedUser: User;
 
  uploadedFiles: any[] = [];
  uploadedattchments: any[] = [];

  edituploadedFiles: Array<any> = [];
  edituploadedattchments: any[] = [];

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public modelattachments = [];
  public editattachmentsdata:any;

  public url : string | ArrayBuffer;

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'fileshare_id', header: 'Id', isVisible: false, isDefault: true },
    { field: 'catgname', header: 'Type', isVisible: true, isDefault: true },
    { field: 'catgprodname', header: 'Selected Type', isVisible: true, isDefault: true },
    { field: 'attachments_count', header: 'Attachment', isVisible: true, isDefault: true },
    // { field: 'prod_desc', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'attachments', header: 'Varint Attachments', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];
  public itemCols: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'variant_name', header: 'Varint Name', isVisible: true, isDefault: true },
    { field: 'attachments', header: 'Varint Attachments', isVisible: true, isDefault: true },
  ];
  selecttype = [
    { label: "Select Type", value: 0 },
    { label: "Variant", value: 1 },
    { label: "Product", value: 2 }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private config: AppConfigService,
    private dataService: FilesharingService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.dataService.getProducts()
      .subscribe(data => {
        this.productsList = data.map(ele => {
          return { label: ele.prod_desc, value: ele };
        });
      });

    // this.dataService.getVariants()
    // .subscribe(data => {
    //   this.variantList = data.map(ele => {
    //     return { label: ele.variant_name, value: ele };
    //   });
    // });

    this.dataService.getUsers()
      .subscribe(data => {
        this.userList = data.map(ele => {
          return { label: ele.user_fname, value: ele };
        });
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultFilesharing);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {

    if (this.dataObj.type_id) {

      console.log(this.dataObj.type_id);  
      //console.log(this.selecttype);
      const item = this.selecttype
      // tslint:disable-next-line: triple-equals
      .find(e => e.value == this.dataObj.type_id);
      this.selectedtype = item ? item.value : undefined;
      //console.log(this.selectedtype);
      this.dataService.getVariants(this.dataObj.type_id)
      .subscribe(data => {
        this.variantList = data.map(ele => {
          return { label: ele.variant_name, value: ele };
        });
        const item = this.variantList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.variant_id == this.dataObj.variant_id);
        this.selectedVariant = item ? item.value : undefined;
        console.log(this.selectedVariant);
      });

    }

    if (this.dataObj.user_id) {
      const item = this.userList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.user_id == this.dataObj.user_id);
      this.selectedUser = item ? item.value : undefined;
    }

  }

  public visible: boolean;
  public attachviewvisible: boolean;

  public showDialog(rowdata) {
      this.visible = true;  
      this.editattachmentsdata = rowdata;
      this.modelattachments =  rowdata.attachments;
      console.log(rowdata);
  }

  public cancelattachEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.url = undefined;
    this.itemObj.variant_code = '';
    this.itemObj.variant_name = '';
    this.visible = false; 
    this.attachviewvisible = false; 
    
  }

  public showAttachviewDialog(rowdata) {
    this.attachviewvisible = true;  
    this.editattachmentsdata = rowdata;
    this.modelattachments =  rowdata.attachments;
    console.log(rowdata);
  }
    

  public get attachdlgHeader() {
    return 'Edit Attchments'
  }

  public onVariantChange(event) {
    console.log(event);
    this.dataObj.variant_id = (event.value as Variants).variant_id;
  } 

  public onProductChange(event) {
    //console.log(event.value.value);
    //console.log(event);
    //console.log(event.value);
    this.dataObj.type_id = event.value;
    //console.log(this.dataObj.type_id);
    this.dataService.getVariants(event.value)
      .subscribe(data => {
        this.variantList = data.map(ele => {
          return { label: ele.variant_name, value: ele };
        });
      });
  }

  public onUserChange(event) {
    this.dataObj.user_id = (event.value as User).user_id;
  }
  public oneditFileChange(event:any) {
    console.log(event);
    if (event.target.files && event.target.files) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
  
        reader.onload = (event:any) => {
          console.log(event.target.result);
            this.edituploadedFiles.push(event.target.result); 
  
          //  this.myForm.patchValue({
          //     fileSource: this.images
          //  });
        }
  
        reader.readAsDataURL(event.target.files[i]);
      }
    }
    this.editattachmentsdata.variant_attachments = this.edituploadedFiles;
    console.log(this.editattachmentsdata);
  }
  public updatevariantimages() {
    this.dataObj.fileshare_image = this.editattachmentsdata;
    // console.log(this.dataObj);
    this.dataService.updateAttachmentsData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.resetFormData();
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.url = undefined;
    this.itemObj.variant_code = '';
    this.itemObj.variant_name = '';
    this.visible = false; 
    this.attachviewvisible = false;
    this.fileUpload = undefined; 
    this.uploadedFiles = undefined;
    this.edituploadedFiles = undefined;
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.uploadedattchments = this.uploadedFiles;
    console.log(this.uploadedattchments);
    this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
  }

  public onFileChange(event:any) {
    console.log(event);
    if (event.target.files && event.target.files) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
    
                reader.onload = (event:any) => {
                  //console.log(event.target.result);
                   this.uploadedFiles.push(event.target.result); 
    
                  //  this.myForm.patchValue({
                  //     fileSource: this.images
                  //  });
                }
   
                reader.readAsDataURL(event.target.files[i]);
        }
        this.dataObj.fileshare_image = this.uploadedFiles;
    }
  }


  public fileUpload(event) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log(event.target.result);
      }
      const file = event.target.files[0];
      this.itemObj.variant_attachment = file;
      this.itemObj.variant_name = file.Name;
    }
  }

  public addToItemList() {
    const tmp = Object.assign({}, this.itemObj);
    tmp.s_no = this.itemDataArray.length + 1;
    tmp.variant_attachment = this.uploadedFiles;
    console.log(tmp);
    console.log(this.uploadedFiles);
    tmp.variant_attachment_count = this.uploadedFiles.length;
    const list = [... this.itemDataArray];
    list.push(tmp);
    this.itemDataArray = list;
    this.uploadedFiles = [];
    this.uploadedattchments = [];
   // this.selectedPack = undefined;
    this.itemObj = Object.assign({}, defaultVariants);
  
}

  public viewImage(file: string) {
    return this.config.ipAddress + 'uploads/fileshare_image/' + file;
  }

  public viewVariantImage(file: string) {
    return this.config.ipAddress + 'uploads/file_share_doc/' + file;
  }

  public viewPdf(file: string) {
    return this.config.ipAddress + 'uploads/file_share_doc/' + file;
  }

  public removeSelectedFile(modelattachments,image) {
    //console.log(modelattachments);
  
     let index = modelattachments.findIndex(e => e.fileshare_id === image);
     if(index!== -1){
      modelattachments.splice(index,1);
     }
     console.log(image);
    
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
 
    this.dataObj.fileshare_image = this.uploadedFiles;
     console.log(this.dataObj);
    //console.log( this.dataObj.fileshare_image);
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
    this.resetFormData();
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.url = undefined;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
    this.resetFormData();
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.fileshare_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Filesharing) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Filesharing) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: Filesharing) {
    this.dataService.updateStatus(obj);
  }
}
