import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { UserRoles } from 'src/app/model';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  public pageName = 'User Role';
  public userRolesList = new Subject<Array<UserRoles>>();

  constructor(
    private config: AppConfigService,
    private http: HttpClient,
    private msgService: MsgService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.userRolesApi[apiOption];
  }

  public getDataRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined }).subscribe((response: any) => {
      if (response && response.result) {
        const UserRolesList: Array<UserRoles> = [];
        response.result.userroleslist.forEach(element => {
          element.status = element.status == '1' ? true : false;
          UserRolesList.push(element);
        });
        this.userRolesList.next(UserRolesList);
      }
    },
      err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
      });
  }

  public addData(userRole: UserRoles) {
    const fData = this.fillData(userRole);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  public updateData(userRole: UserRoles) {
    const fData = this.fillData(userRole);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  private fillData(objData: UserRoles) {
    const fData = new FormData();
    fData.append('role_id', objData.role_id + '');
    fData.append('role_name', objData.role_name);
    // fData.append('userroleManager', objData.userRoleMngr);
    // fData.append('menuManageIds', objData.userRoleMenuIds);
    fData.append('status', objData.status ? '1' : '0');
    return fData;
  }

  public updateStatus(objData: UserRoles) {
    const fData = new FormData();
    fData.append('role_id', objData.role_id + '');
    fData.append('status', objData.status ? '1' : '0');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('role_id', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public getRolesData() {
    const url = this.config.ipAddress + this.config.userRolesApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        const userRolesList: Array<UserRoles> = [];
        response.result.userroleslist.forEach(element => {
          element.userroleStatus = element.userroleStatus ? true : false;
          userRolesList.push(element);
        });
        this.userRolesList.next(userRolesList);
      }
    },
      err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
      });
  }

}
