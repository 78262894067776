import { AppConfigService } from './app-config.service';

export const AppServiceFactory = () => {
    // Create Config service object.
    const env = new AppConfigService();
    const envKey = '__key';

    // Read environment variables from browser window
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow[envKey] || {};

    // Assign environment variables from browser window to env
    // In the current implementation, properties from app-config.js overwrite defaults from the AppConfigService.
    // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
    for (const key in browserWindowEnv) {
        if (browserWindowEnv.hasOwnProperty(key)) {
            env[key] = window[envKey][key];
        }
    }

    return env;
};

export const AppServiceProvider = {
    provide: AppConfigService,
    useFactory: AppServiceFactory,
    deps: [],
};
