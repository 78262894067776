export interface Pushnotification {
    s_no?: number;
    pnotification_id: number;
    country_id: number;
    state_id: number;
    city_code: string;
    pnotification_desc: string;
    pnotification_title: string;
    status: number;
    rec_status: number;
    
}

export const defaultPushnotification = {
    pnotification_id: 0,
    country_id: 0,
    state_id: 0,
    city_code: '',
    pnotification_desc: '',
    pnotification_title: '',
    status: 0,
    rec_status: 0
};
