export interface UserMenus {
    s_no?: number;
    role_id: number;
    userMngr: string;
    userRoleMenuIds: string;
    status: boolean;
}

export const defaultUserMenus: UserMenus = {
    role_id: 0,
    userMngr: '',
    userRoleMenuIds: '',
    status: false
};
