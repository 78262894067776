export interface Designation {
    s_no?: number;
    desi_id: number;
    desi_code: string;
    desi_name: string;
    status: number;
}

export const defaultDesignation = {
    desi_id: 0,
    desi_code: '',
    desi_name: '',
    status: 0
};
