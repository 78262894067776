import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { AdminMenu, ServiceActions } from 'src/app/model';
import { Subject } from 'rxjs';
import { ApiActions } from './util.service';
import { CommonDataService } from './common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class TopsearchService {
  public dataList = new Subject<Array<any>>();
  public adminMenuList = new Subject<Array<any>>();
  constructor( private http: HttpClient, private config: AppConfigService,private msgSvc: MsgService) { }
private apiData = new Subject<any>();
public apiData$ = this.apiData.asObservable();
private getUrl(apiOption: ApiActions) {
  return this.config.ipAddress + this.config.profiledata[apiOption];
}

  public fetchData(message:string) {
    const fData = new FormData();
    fData.append('message', message);
    return this.http.post(this.getUrl(ApiActions.UpdateStatus),fData,{ headers: undefined });
  }
  public getDataRecords(obj) {
    const fData = new FormData();
    fData.append('row_id', obj.row_id);
    fData.append('prim_id', obj.prim_id);
    fData.append('locate_code', obj.locate_code);
    this.http.post(this.config.ipAddress + this.config.profiledata[ApiActions.Delete]
      , fData
      , { headers: undefined }).subscribe((response: any) => {
        if (response && response) {
          const adminLst = [];
          response.forEach(element => {
          //  element.status = element.status == '1' ? true : false;
            adminLst.push(element);
          });
          this.adminMenuList.next(adminLst);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });
        });
  }

// here we set/change value of the observable
setData(data) { 
  this.apiData.next(data)
}
}
