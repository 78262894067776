export interface UserRoles {
    s_no?: number;
    userRoleId: number;
    role_name: string;
    role_id: number;
    userRole: string;
    userRoleMngr: string;
    userRoleMenuIds: string;
    userMenuIds: string;
    status: boolean;
    rec_status : boolean;
    menu_manage_ids: string;
}

export const defaultUserRoles: UserRoles = {
    userRoleId: 0,
    role_id:0,
    role_name: '',
    userRole: '',
    userRoleMngr: '',
    userRoleMenuIds: '',
    userMenuIds: '',
    status: false,
    rec_status:false,
    menu_manage_ids: ''
};
