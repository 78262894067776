import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { Posts,Country,State, ServiceActions } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PostsService implements ServiceActions {

  public pageName = 'Posts';
  public dataList = new Subject<Array<Posts>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.PostsApi[apiOption];
  }

  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Posts> = [];
          response.result.Posts.map(element => {
            element.status = element.rec_status == '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  // public getCountrys(): Subject<Array<Country>> {
  //   const url = this.config.ipAddress + this.config.CountryApi[ApiActions.Get];
  //   const dataArray: Array<Country> = [];
  //   const data = new Subject<Array<Country>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.Country.map(element => {
  //           element.status = Number(element.rec_status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }
  // public getStates(): Subject<Array<State>> {
  //   const url = this.config.ipAddress + this.config.StateApi[ApiActions.Get];
  //   const dataArray: Array<State> = [];
  //   const data = new Subject<Array<State>>();
  //   this.http.post(url, null, { headers: undefined })
  //     .subscribe((response: any) => {
  //       if (response && response.result) {
  //         response.result.State.map(element => {
  //           element.status = Number(element.rec_status) === 1 ? true : false;
  //           dataArray.push(element);
  //         });
  //         data.next(dataArray);
  //       }
  //     },
  //       err => {
  //           this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
  //       });
  //   return data;
  // }

  public addData(objData: Posts) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: Posts) {
    const fData = new FormData();
    fData.append('post_title', objData.post_title + '');
    fData.append('post_id', objData.post_id + '');
    // fData.append('StateId', objData.state_id + '');
    fData.append('short_desc', objData.short_desc);
    fData.append('long_desc', objData.long_desc);
    fData.append('rec_status', objData.rec_status ? '1' : '0');

    return fData;
  }

  public updateData(objData: Posts) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('post_title', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Posts) {
    const fData = new FormData();
    fData.append('rec_status', objData.rec_status ? '1' : '0');
    fData.append('post_title', objData.post_title + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

}
