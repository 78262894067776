import { Component, OnInit, OnDestroy, ElementRef, ViewChild  } from '@angular/core';
import { MasterActions, GridHeader, Testimonials, defaultTestimonials, GridHeaderEx } from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, TestimonialsService, UtilService,UserType, AppConfigService } from 'src/app/service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit, OnDestroy, MasterActions {
  @ViewChild('tprofile_image', { static: true }) specDoc: ElementRef;
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Testimonials>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Testimonials = defaultTestimonials;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};
  public url : string | ArrayBuffer;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'testmoni_name', header: 'Name', isVisible: true, isDefault: true },
    { field: 'tprofile_image', header: 'Image', isVisible: true, isDefault: true },
    { field: 'testmoni_msg', header: 'Comment', isVisible: true, isDefault: true },
    { field: 'testmoni_rating', header: 'Rating ', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private config: AppConfigService,
    private dataService: TestimonialsService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultTestimonials);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
    }
  }

	public fileUpload(event) {
		const file = event.target.files[0];
		this.dataObj.tprofile_image = file;
	}

  public onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log(event.target.result);
      }
      const file = event.target.files[0];
      this.dataObj.tprofile_image = file;
    }
  }
  
  public viewImage(file: string) {
    return this.config.ipAddress + 'uploads/tprofile_image/' + file;
  }


  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
        this.resetFormData();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.url = undefined;
  }

  public updateCallType() {
    console.log(this.dataObj);
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
      this.resetFormData();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.testmoni_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Testimonials) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Testimonials) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: Testimonials) {
    this.dataService.updateStatus(obj);
  }
}
