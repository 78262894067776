export interface City {
    s_no?: number;
    city_id: number;
    country_id: number;
    state_id: number;
    city_code: string;
    city_desc: string;
    city_name: string;
    status: number;
    rec_status: number;
    
}

export const defaultCity = {
    city_id: 0,
    country_id: 0,
    state_id: 0,
    city_code: '',
    city_desc: '',
    city_name: '',
    status: 0,
    rec_status: 0
};
