import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { ApiActions,UtilService } from './util.service';
import { Subject } from 'rxjs';
import { AdminMenu } from 'src/app/model';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';

export interface MenuInfo {
  name: string;
  link: string;
  isTitle:boolean;
  icon:string;
}

export interface MenuItem {
  menu: string;
  subMenus: Array<MenuInfo>;
}

export interface NotificationMsg {
  sno?: number;
  uuid: number;
  ntfDesc: string;
  ntfStatus: number;
  userId: number;
}

export interface ActivityMsg {
  date: Date;
  status: string;
}
export interface BatchListCalenderMsg {
  sno?: number;
  BatchId: number;
  BatchDesc: string;
  BatchStatus: number;
  BatchDate: Date;
  status: number;
}

export interface BatchDetailsMsg {
  sno?: number;
  BatchId: number;
  BatchDesc: string;
  BatchStatus: number;
  BatchDate: Date;
}

export const defaultCSS = 'app layout-fixed-header';
const hideMenuCSS = 'app layout-fixed-header layout-small-menu';


@Injectable({
  providedIn: 'root'
})
export class LeftMenuService {
  public menus = new Subject<Array<{ menu: string, subMenus: Array<MenuInfo> }>>();
  public menusFromDB: Array<AdminMenu> = [];
  public menuListForUserRoles = new Subject<Array<any>>();
  public menuListForUserMenus = new Subject<Array<any>>();
//  public editmenuListForUserMenus = new Subject<Array<AdminMenu>>();
  private menuCollapse = new Subject<string>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
		private msgSvc: MsgService,
		private router: Router,
    private util: UtilService
  ) { }


  public hideMenu() {
    this.menuCollapse.next(defaultCSS);
    setTimeout(() => {
      this.menuCollapse.next(hideMenuCSS);
    }, 100);
  }

  public toggleMenu() {
    return this.menuCollapse;
  }

  public getMenus() {
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Get], null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<AdminMenu> = [];
          // TODO: it's for just tmp code...
          // -------------------------------------------
          response.result.AdminMenu.map(element => {
            const temp = element as AdminMenu;
            dataArray.push(temp);
          });
          this.menusFromDB = dataArray;
        //  console.log(dataArray);
          this.initMenus();
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }
  public geteditMenus(selected_ids:string) {
    const editmenuListForUserMenus = new Subject<Array<any>>();
    const fData = new FormData();
    fData.append('selected_ids',selected_ids);
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Delete], fData , { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result.AdminMenu) {
          const nCount = response.result.AdminMenu.map((e, indx) =>{           
            //  e['key'] = e.menuID;     
              return e;

          });
          editmenuListForUserMenus.next(nCount);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return editmenuListForUserMenus;
  }
  private initMenus() {
    let menus = [];
    // Menu Headers
    
   // menus.push( {label: 'Navigation',isTitle: true},{label: 'Dashboard',icon: 'home',link: 'home'});
    const menuHeaders = this.menusFromDB.filter(m => m.menuParentId == 0);
    for (const menu of menuHeaders) {
      const subMenus = this.menusFromDB.filter(m => m.menuParentId == menu.menuID);
      const menuItem = subMenus.map(m => {
        return { name: m.menuDesc, link: m.menuLink } as MenuInfo;
      });
      menus.push({ menu: menu.menuDesc, subMenus: menuItem });
    }
    
    menus = [];

    const menuItems = this.menusFromDB.map(m => {
      return { name: m.menuDesc, link: m.menuLink } as MenuInfo;
    });
    menus.push({ menu: 'Admin Menu', subMenus: menuItems });
 console.log('the menu items are:', menuItems);
    this.menus.next(menus);
  }

  

  public getMenuFromDBForUserRoles() {
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Get]
      , null
      , { headers: undefined }).subscribe((response: any) => {
        if (response && response.result) {
          this.menuListForUserRoles.next(response.result.AdminMenu);
		  
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }
  public getMenuFromDBForUserMenus() {
    this.http.post(this.config.ipAddress + this.config.leftMenuApi[ApiActions.Get]
      , null
      , { headers: undefined }).subscribe((response: any) => {
        if (response && response.result) {
          this.menuListForUserMenus.next(response.result.AdminMenu);
		  
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

}
