export interface EquipmentMaster {
    s_no?: number;
    equipmentId: number;
    equipmentName: string;
    equipmentCode: string;
    equipmentTypeId: number;
    productId: number;
    equipmentSubTypeId: number;
    equipmentCapacity: number;
    uom_id: number;
    equipmentMake: string;
    equipmentTypeDesc?: string;
    equipmentSubTypeDesc?: string;
    prodName?: string;
    dateCommission: Date;
    status: number;
}

export const defaultEquipmentMaster: EquipmentMaster = {
    equipmentId: 0,
    equipmentName: '',
    equipmentCode: '',
    equipmentTypeId: 0,
    productId: 0,
    equipmentSubTypeId: 0,
    equipmentCapacity: 0,
    uom_id: 0,
    equipmentMake: '',
    dateCommission: new Date(),
    status: 0
};
