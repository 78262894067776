export interface User {
    s_no?: number;
    userId?: number;
    user_id?: number;
    userFName: string;
    userLName: string;
    userLogin: string;
    user_fname: string;
    user_lname: string;
    user_login: string;
    user_pwd: string;
    user_gender: string;
    user_age: string;
    city_id: number;
    role_id: number;
    userRoleId: number;
    status: number;
    userManagerId: number;
    userRoleCode: string;
    password?: string;
    rec_status: boolean;
    city_name: string;
    userRolename: string;
    user_phone: string;
    author_name: string;
}

export const defaultUser: User = {
    userId: 0,
    user_id: 0,
    userFName: '',
    userLName: '',
    userLogin: '',

    user_fname: '',
    user_lname: '',
    user_login: '',
    user_pwd: '',
    user_gender: '',
    user_age: '',
    city_id: 0,
    city_name: '',
    role_id: 0,
    userRoleId: 0,
    status: 0,
    userManagerId: 0,
    userRoleCode: '',
    password: '',
    rec_status: false,
    userRolename: '',
    user_phone: '',
    author_name: '',
    
};
