import { NgModule } from '@angular/core';
//import { BrowserModule } from '@angular/platform-browser';

import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { CalendarModule } from 'primeng/calendar';
import { SpinnerModule } from 'primeng/spinner';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CheckboxModule } from 'primeng/checkbox';

const modules = [
  //BrowserModule
   TooltipModule
  , ProgressSpinnerModule
  , BlockUIModule
  , MessagesModule
  , MessageModule
  , ToastModule
  , TableModule
  , DialogModule
  , ButtonModule
  , InputSwitchModule
  , DropdownModule
  , InputTextModule
  , ListboxModule
  , PanelModule
  , ScrollPanelModule
  , VirtualScrollerModule
  , CalendarModule
  , SpinnerModule
  , KeyFilterModule
  , CheckboxModule
];

@NgModule({
  exports: modules
})
export class PrimeNGModule { }
