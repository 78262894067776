export interface Tags {
    s_no?: number;
    tag_id: number;
    tag_name: string;
    status: number;
    parent_id: number;
    rec_status: number;
    parent_name: string;
}

export const defaultTags = {
    tag_id: 0,
    tag_name: '',
    status: 0,
    parent_id:0,
    rec_status: 0,
    parent_name: ''
};
