import { Component, OnInit, OnDestroy } from '@angular/core';
import { MasterActions, GridHeader, Playlists, defaultPlaylists, GridHeaderEx } from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, PlaylistsService,UserType,UtilService } from 'src/app/service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss']
})
export class PlaylistsComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Playlists>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Playlists = defaultPlaylists;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editCallTypeList: any = {};
  public itemDataArray: Array<any> = [];
  public playlistsList: Array<SelectItem> = [];
  public selectedPlaylists: Playlists;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	  { field: 'playlists_id', header: 'Id', isVisible: false, isDefault: true },
    { field: 'user_fname', header: 'User Name ', isVisible: true, isDefault: true },
    { field: 'plist_name', header: 'Playlist Name', isVisible: true, isDefault: true },
    { field: 'created_on', header: 'Created On', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: false, isDefault: true },
  ];


  public itemCols = [
    { field: 's_no', header: 'S No' },
    { field: 'prod_code', header: 'Product Name' },
    { field: 'created_on', header: 'Created On' }
  ];
  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    public util: UtilService,
    private dataService: PlaylistsService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    
      this.subscription.add(
        this.dataService.singledataList.subscribe((data) => {
          this.itemDataArray = [];
          if (data.length) {
            this.itemDataArray = data.map((e, indx) => {
              e.s_no = indx + 1;
              return e;
            });
          } else {
            this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
          }
        }));
      // this.dataService.getPlaylists()
      // .subscribe(data => {
      //   this.playlistsList = data.map(ele => {
      //     return { label: ele.playlists_name, value: ele };
      //   });
      // });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultPlaylists);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
	   this.bindDropDownList();
    }
  }
  private bindDropDownList() {

    if (this.dataObj.playlists_id) {
      const item = this.playlistsList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.playlists_id == this.dataObj.playlists_id);
      this.selectedPlaylists = item ? item.value : undefined;
    }
  }
  public onPlaylistsChange(event) {
    this.dataObj.playlists_id = (event.value as Playlists).playlists_id;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.playlists_id).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Playlists) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Playlists) {
    this.dataObj = obj;  
    this.dataService.getDataRecordsbyid(this.dataObj.playlist_id);
    this.isViewDetails = true;
  }

  public updateStatus(obj: Playlists) {
    this.dataService.updateStatus(obj);
  }
}
