export interface Testimonials {
    s_no?: number;
    testmoni_id: number;
    testmoni_msg: string;
    testmoni_name: string;
    status: number;
    tprofile_image: File | string;
    testmoni_rating: string;
}

export const defaultTestimonials = {
    testmoni_id: 0,
    testmoni_msg: '',
    testmoni_name: '',
    status: 0,
    tprofile_image: '',
    testmoni_rating: ''
};
