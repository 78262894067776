export interface Review {
    s_no?: number;
    review_id: number;
    rating: string;
    review: string;
    user_id: number;
    prod_id: number;
    variant_id: number;
    status: boolean;
    rec_status: boolean;
}

export const defaultReview = {
    review_id: 0,
    rating: '',
    review: '',
    user_id: 0,
    prod_id: 0,
    variant_id: 0,
    status: false,
    rec_status: false
};
