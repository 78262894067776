import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { MsgService } from './msg.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  constructor(
    private http: HttpClient,
    private msgSvc: MsgService
  ) { }

  public executeAction(formData: FormData, actionUrl: string, pageName: string) {
    const responseMsg = new Subject<string>();
    this.http.post(actionUrl, formData, { headers: undefined }).subscribe((response: any) => {
      if (response.status !== 'error') {
        this.msgSvc.addSuccessMsg({ summary: pageName, detail: response.status });
      } else {
        this.msgSvc.addErrorMsg({ summary: pageName, detail: response.Error });
      }
      responseMsg.next(response.status);
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }

  public deleteexecuteAction(formData: FormData, url: string, pageName: string): Observable<any> {
    return this.http.post(url, formData).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Error occurred:', error);
        return throwError(() => error);
      })
    );
  }
}
